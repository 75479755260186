import { jsx as _jsx } from "react/jsx-runtime";
import { usePermission } from '@hooks/usePermission';
import { ROUTES } from '@router/routes';
import { getHasExistingToken } from '@utils/accessTokenCookie';
import { Navigate } from 'react-router-dom';
import { useCanAccessFarmerProfile } from '@/guards/useCanAccessFarmerProfile';
const Guard = ({ target, guards }) => {
    let redirectUrl = null;
    const { canSeeDashboard, canManageFarmer, canSeePartnerMembers, canSeeAlarmingData, canSeeAssignedAlarmingData, canSeePartnerAlarmingData, canSeeAssignedPartnerAlarmingData, } = usePermission();
    const { hasAccessTokenCookie } = getHasExistingToken();
    const { canAccessProfile, isLoading: isProfileAccessLoading } = useCanAccessFarmerProfile();
    const isLoadingAccess = isProfileAccessLoading;
    const UA = window.navigator.userAgent;
    // Strategy comes from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
    // exception added for ipad. 'Mobi' doesn't look ok for ios to differentiate tablet and mobile phone. Contrary to android devices: https://developers.google.com/search/blog/2011/03/mo-better-to-also-detect-mobile-user
    const isMobile = /Mobi/i.test(UA) && !/iPad/i.test(UA);
    if (isLoadingAccess)
        return null;
    for (let i = 0; i < guards.length; i++) {
        switch (guards[i]) {
            case 'authenticated':
                if (!hasAccessTokenCookie) {
                    redirectUrl = ROUTES.LOGIN;
                    break;
                }
                break;
            case 'unauthenticated':
                if (hasAccessTokenCookie) {
                    if (canManageFarmer) {
                        redirectUrl = ROUTES.FARMERS;
                        break;
                    }
                    if (canSeePartnerMembers) {
                        redirectUrl = ROUTES.MEMBERS;
                        break;
                    }
                }
                break;
            case 'canSeeDashboard':
                if (!canSeeDashboard) {
                    if (canManageFarmer) {
                        redirectUrl = ROUTES.FARMERS;
                        break;
                    }
                    if (canSeePartnerMembers) {
                        redirectUrl = ROUTES.MEMBERS;
                        break;
                    }
                    redirectUrl = ROUTES.LOGIN;
                    break;
                }
                break;
            case 'canSeeMembers':
                if (!canSeePartnerMembers) {
                    redirectUrl = ROUTES.NOT_FOUND;
                    break;
                }
                break;
            case 'canManageFarmer':
                if (!canManageFarmer) {
                    redirectUrl = ROUTES.NOT_FOUND;
                    break;
                }
                break;
            case 'canSeeAlarmingData':
                if (!canSeeAlarmingData &&
                    !canSeeAssignedAlarmingData &&
                    !canSeePartnerAlarmingData &&
                    !canSeeAssignedPartnerAlarmingData) {
                    redirectUrl = ROUTES.NOT_FOUND;
                    break;
                }
                break;
            case 'canAccessProfile':
                if (!canAccessProfile && !isProfileAccessLoading) {
                    redirectUrl = ROUTES.FARMERS;
                    break;
                }
                break;
            case 'isMobile':
                if (isMobile) {
                    redirectUrl = '/mobile-hardstop';
                }
                break;
            default:
                redirectUrl = 'login';
                break;
        }
    }
    return redirectUrl ? _jsx(Navigate, { to: redirectUrl }) : target;
};
export default Guard;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { skipToken } from '@reduxjs/toolkit/query';
import { ALARMING_DATA_TYPE, useGetAlarmingDataQuery, useGetAlarmingDataProcessQuery, } from '@services';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
export const ALARMING_DATA_SCOPE = {
    FARM_SEASON: 'FARM_SEASON',
    FARM_SEASON_FIELD: 'FARM_SEASON_FIELD',
    FARM_SEASON_FIELD_CROP: 'FARM_SEASON_FIELD_CROP',
};
export const getAlarmingDataScope = (data) => {
    if (data.farmSeasonField)
        return ALARMING_DATA_SCOPE.FARM_SEASON_FIELD;
    if (data.farmSeasonFieldCrop)
        return ALARMING_DATA_SCOPE.FARM_SEASON_FIELD_CROP;
    return ALARMING_DATA_SCOPE.FARM_SEASON;
};
export const computeAlarmingData = (data) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return ({
        id: data.id,
        type: data.alarming_data_check.type,
        values: data.values ? JSON.parse(data.values) : null,
        resolvedAt: data.resolved_at,
        check: {
            id: data.alarming_data_check.id,
            name: data.alarming_data_check.name,
            isJustificationMandatory: data.alarming_data_check.is_justification_mandatory,
        },
        farmSeason: ((_a = data === null || data === void 0 ? void 0 : data.alarming_farm_season_data) === null || _a === void 0 ? void 0 : _a.id)
            ? {
                id: data.alarming_farm_season_data.id,
            }
            : null,
        farmSeasonField: ((_b = data === null || data === void 0 ? void 0 : data.alarming_farm_season_field_data) === null || _b === void 0 ? void 0 : _b.farm_season_field_id)
            ? {
                id: (_d = (_c = data.alarming_farm_season_field_data) === null || _c === void 0 ? void 0 : _c.farm_season_field) === null || _d === void 0 ? void 0 : _d.id,
                name: (_f = (_e = data.alarming_farm_season_field_data) === null || _e === void 0 ? void 0 : _e.farm_season_field) === null || _f === void 0 ? void 0 : _f.name,
            }
            : null,
        farmSeasonFieldCrop: ((_g = data === null || data === void 0 ? void 0 : data.alarming_farm_season_field_crop_data) === null || _g === void 0 ? void 0 : _g.id)
            ? {
                id: data === null || data === void 0 ? void 0 : data.alarming_farm_season_field_crop_data.id,
                name: (_l = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.alarming_farm_season_field_crop_data) === null || _h === void 0 ? void 0 : _h.farm_season_field_crop) === null || _j === void 0 ? void 0 : _j.farm_season_crop) === null || _k === void 0 ? void 0 : _k.crop) === null || _l === void 0 ? void 0 : _l.name,
            }
            : null,
        group: (data === null || data === void 0 ? void 0 : data.alarming_data_group)
            ? {
                id: (_m = data.alarming_data_group) === null || _m === void 0 ? void 0 : _m.id,
                name: (_o = data.alarming_data_group) === null || _o === void 0 ? void 0 : _o.name,
                comments: (_q = (_p = data === null || data === void 0 ? void 0 : data.alarming_data_group) === null || _p === void 0 ? void 0 : _p.alarming_data_group_comments) === null || _q === void 0 ? void 0 : _q.map((comment) => {
                    var _a, _b, _c;
                    return ({
                        id: comment.id,
                        value: comment.value,
                        author: {
                            id: ((_a = comment.author) === null || _a === void 0 ? void 0 : _a.id) || 0,
                            firstName: ((_b = comment.author) === null || _b === void 0 ? void 0 : _b.first_name) || 'User deactivated',
                            lastName: ((_c = comment.author) === null || _c === void 0 ? void 0 : _c.last_name) || '',
                        },
                    });
                }),
            }
            : null,
    });
};
const createNewGroup = (data) => {
    var _a, _b, _c;
    return ({
        id: ((_a = data.group) === null || _a === void 0 ? void 0 : _a.id) || null,
        name: ((_b = data.group) === null || _b === void 0 ? void 0 : _b.name) || null,
        alarmingData: [data],
        comments: ((_c = data.group) === null || _c === void 0 ? void 0 : _c.comments) || [],
        isResolved: !!data.resolvedAt,
    });
};
const createNewCheck = (data) => {
    var _a, _b;
    return ({
        id: data.check.id,
        scope: getAlarmingDataScope(data),
        name: data.check.name,
        groups: ((_a = data.group) === null || _a === void 0 ? void 0 : _a.id) ? [createNewGroup(data)] : [],
        ungrouped: !((_b = data.group) === null || _b === void 0 ? void 0 : _b.id) ? [data] : [],
        count: 1,
    });
};
export const isAlarmingDataConfirmationDisabled = (alarmingData) => {
    var _a, _b, _c;
    return (alarmingData.check.isJustificationMandatory &&
        (((_c = ((_b = (_a = alarmingData.group) === null || _a === void 0 ? void 0 : _a.comments) !== null && _b !== void 0 ? _b : [])) === null || _c === void 0 ? void 0 : _c.length) < 1 || !alarmingData.group));
};
export const canConfirmAlarmingData = (alarmingData) => !alarmingData.resolvedAt;
export const sortAndComputeAlarmingData = (data) => {
    const initialState = {
        resolved: {
            yoy: [],
            benchmark: [],
            count: 0,
        },
        unresolved: {
            yoy: [],
            benchmark: [],
            count: 0,
        },
    };
    return data.reduce((acc, item) => {
        var _a, _b, _c;
        const computedItem = computeAlarmingData(item);
        const status = computedItem.resolvedAt ? 'resolved' : 'unresolved';
        const type = computedItem.type === ALARMING_DATA_TYPE.YOY ? 'yoy' : 'benchmark';
        const checkIndex = acc[status][type].findIndex((check) => check.id === computedItem.check.id);
        const check = (_a = acc[status][type]) === null || _a === void 0 ? void 0 : _a[checkIndex];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        status === 'resolved' ? (acc.resolved.count += 1) : (acc.unresolved.count += 1);
        if (check) {
            check.count += 1;
            const groupIndex = acc[status][type][checkIndex].groups.findIndex((group) => { var _a; return (group === null || group === void 0 ? void 0 : group.id) === ((_a = computedItem.group) === null || _a === void 0 ? void 0 : _a.id); });
            const group = (_b = acc[status][type][checkIndex].groups) === null || _b === void 0 ? void 0 : _b[groupIndex];
            if (group) {
                group.alarmingData.push(computedItem);
            }
            else {
                if ((_c = computedItem.group) === null || _c === void 0 ? void 0 : _c.id) {
                    acc[status][type][checkIndex].groups.push(createNewGroup(computedItem));
                }
                else {
                    acc[status][type][checkIndex].ungrouped.push(computedItem);
                }
            }
        }
        else {
            acc[status][type].push(createNewCheck(computedItem));
        }
        return acc;
    }, initialState);
};
export const useAlarmingDataComputation = () => {
    const { farmSeasonId: farmSeasonIdParam } = useParams();
    const farmSeasonId = farmSeasonIdParam ? parseInt(farmSeasonIdParam, 10) : undefined;
    const { data: alarmingDataProcessData, isLoading: isLoadingProcess, isError: isErrorProcess, } = useGetAlarmingDataProcessQuery(farmSeasonId ? { farmSeasonId } : skipToken);
    const shouldFetchAlarmingData = Boolean(alarmingDataProcessData);
    const _a = useGetAlarmingDataQuery(farmSeasonId ? { farmSeasonId } : skipToken, { skip: !shouldFetchAlarmingData }), { data: alarmingData, isLoading: isLoadingAlarmingData, isFetching: isFetchingAlarmingData, isError: isErrorAlarmingData } = _a, alarmingDataQueryResult = __rest(_a, ["data", "isLoading", "isFetching", "isError"]);
    const isLoading = isLoadingProcess || isLoadingAlarmingData;
    const isFetching = isFetchingAlarmingData;
    const isError = isErrorProcess || isErrorAlarmingData;
    const computedAlarmingData = useMemo(() => sortAndComputeAlarmingData(alarmingData || []), [alarmingData]);
    return Object.assign({ data: computedAlarmingData, isLoading,
        isFetching,
        isError }, alarmingDataQueryResult);
};

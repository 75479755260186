var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl, MenuItem, TextField } from '@mui/material';
import { useSelectChipsStyle } from './SelectChips.style';
import { SelectedChips } from '@components/Form/SelectedChips/SelectedChips';
export const SelectChips = (_a) => {
    var { label, values, options, onChange, onReset, getOptionLabel, getOptionKey, getOptionValue, onChipDelete, className, maxChips, remainingChipsLabel, shouldGrow = false } = _a, rest = __rest(_a, ["label", "values", "options", "onChange", "onReset", "getOptionLabel", "getOptionKey", "getOptionValue", "onChipDelete", "className", "maxChips", "remainingChipsLabel", "shouldGrow"]);
    const { classes } = useSelectChipsStyle();
    const selectedValues = values === null || values === void 0 ? void 0 : values.map((v) => getOptionValue(v));
    const optionValueLookup = {};
    options.map((option) => {
        const optionValue = getOptionValue(option);
        optionValueLookup[optionValue] = option;
    });
    const convertSelectedValueToOptionObject = (value) => optionValueLookup[value];
    const handleChange = (event) => {
        const selectedValues = event.target.value;
        const convertedValues = selectedValues.map((v) => convertSelectedValueToOptionObject(v));
        onChange(convertedValues);
    };
    const handleChipDelete = (chip) => {
        const convertedDeletedValue = convertSelectedValueToOptionObject(chip.key);
        onChipDelete === null || onChipDelete === void 0 ? void 0 : onChipDelete(convertedDeletedValue);
    };
    return (_jsx(FormControl, { className: className, children: _jsx(TextField, Object.assign({ className: classes.textField, label: label, select: true, value: selectedValues, onChange: handleChange, InputProps: {
                className: classes.input,
            }, SelectProps: {
                multiple: true,
                renderValue: (selectedValues) => {
                    const selectedOptions = selectedValues.map((value) => optionValueLookup[value]);
                    const selectedValuesLabels = selectedOptions.map((option) => ({
                        key: getOptionKey(option),
                        value: getOptionLabel(option),
                    }));
                    return (_jsx(SelectedChips, { chips: selectedValuesLabels, maxChips: maxChips, onChipDelete: handleChipDelete, remainingChipsLabel: remainingChipsLabel, shouldGrow: shouldGrow, onReset: onReset }));
                },
            } }, rest, { children: options.map((option) => (_jsx(MenuItem, { value: getOptionValue(option), children: getOptionLabel(option) }, getOptionKey(option)))) })) }));
};

import { makeStyles } from 'tss-react/mui';
export const useMemberEditModalStyles = makeStyles()((theme) => ({
    title: {
        marginBottom: theme.spacing(6),
    },
    buttonsContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightActionsContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        alignItems: 'center',
    },
}));

import { deleteFromStorage, parseStorageObject } from '@utils/localstorage';
export const SEASON_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-season-filter';
export const COHORT_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-cohort-filter';
export const PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-partner-filter';
export const CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-contact-filter';
export const USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-user-status-filter';
export const FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-farm-season-status-filter';
export const HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-hidden-columns-filter';
export const useFarmersListStorageFilters = () => {
    const storageSeasonFilter = parseStorageObject(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageCohortFilter = parseStorageObject(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storagePartnerFilter = parseStorageObject(PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageContactFilter = parseStorageObject(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageUserStatusFilter = parseStorageObject(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageFarmSeasonStatusFilter = parseStorageObject(FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageHiddenColumnsFilter = parseStorageObject(HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeSeasonFilterToStorage = (season) => localStorage.setItem(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(season[0].ids));
    const deleteSeasonFilterFromStorage = () => deleteFromStorage(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeCohortFilterToStorage = (cohorts) => localStorage.setItem(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(cohorts.map((cohort) => cohort.id)));
    const deleteCohortFilterFromStorage = () => deleteFromStorage(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storePartnerFilterToStorage = (partners) => localStorage.setItem(PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(partners.map((partner) => partner.id)));
    const deletePartnerFilterFromStorage = () => deleteFromStorage(PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeContactFilterToStorage = (contacts) => localStorage.setItem(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(contacts.map((contact) => contact.id)));
    const deleteContactFilterFromStorage = () => deleteFromStorage(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeUserStatusFilterToStorage = (userStatuses) => localStorage.setItem(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(userStatuses));
    const deleteUserStatusFilterFromStorage = () => deleteFromStorage(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeFarmSeasonStatusFilterToStorage = (farmSeasonStatuses) => localStorage.setItem(FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(farmSeasonStatuses));
    const deleteFarmSeasonStatusFilterFromStorage = () => deleteFromStorage(FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageHiddenColumnsFilterToStorage = (hiddenColumns) => localStorage.setItem(HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(hiddenColumns));
    const deleteHiddenColumnsFilterFromStorage = () => deleteFromStorage(HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY);
    return {
        storageSeasonFilter,
        storeSeasonFilterToStorage,
        deleteSeasonFilterFromStorage,
        storageCohortFilter,
        storeCohortFilterToStorage,
        deleteCohortFilterFromStorage,
        storagePartnerFilter,
        storePartnerFilterToStorage,
        deletePartnerFilterFromStorage,
        storageContactFilter,
        storeContactFilterToStorage,
        deleteContactFilterFromStorage,
        storageUserStatusFilter,
        storeUserStatusFilterToStorage,
        deleteUserStatusFilterFromStorage,
        storageFarmSeasonStatusFilter,
        storeFarmSeasonStatusFilterToStorage,
        deleteFarmSeasonStatusFilterFromStorage,
        storageHiddenColumnsFilter,
        storageHiddenColumnsFilterToStorage,
        deleteHiddenColumnsFilterFromStorage,
    };
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Avatar } from '@components/Avatar';
import { getAvatarInitials } from '@utils/string';
import { useFarmerProfileAboutCardStyles } from '@features/farmer/profile/components/FarmerProfileAboutCard/FarmerProfileAboutCard.style';
export const ContactList = ({ contacts }) => {
    const { t } = useTranslation();
    const hasAssignedContact = contacts && contacts.length > 0;
    const { classes } = useFarmerProfileAboutCardStyles();
    if (!hasAssignedContact)
        return _jsx(Typography, { variant: "body1", children: t('farmers.profile.about.no-contact') });
    return (_jsx(_Fragment, { children: contacts.map((contact) => (_jsxs("div", { className: classes.contact, children: [_jsx(Avatar, { children: getAvatarInitials(contact.first_name, contact.last_name, contact.email) }), _jsxs(Typography, { variant: "body1", children: [contact.first_name, " ", contact.last_name] })] }, Number(contact.id)))) }));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ALARMING_DATA_SCOPE, canConfirmAlarmingData, isAlarmingDataConfirmationDisabled, } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { IconMinus } from '@components/Icons';
import { AlarmingDataComments } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataComments';
import { Button, Checkbox, CircularProgress, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmingDataInfo } from '../AlarmingDataInfo/AlarmingDataInfo';
import { useAlarmingDataSelectionStyles } from './AlarmingDataSelection.style';
export const AlarmingDataSelection = ({ className, data, scope, onConfirm, isLoading }) => {
    const { t } = useTranslation();
    const { classes, cx } = useAlarmingDataSelectionStyles();
    const [selectedAlarmingDataIds, setSelectedAlarmingDataIds] = useState([]);
    const alarmingDataIds = data.map((d) => d.id);
    const areAllSelected = alarmingDataIds.length === selectedAlarmingDataIds.length;
    const areNoneSelected = selectedAlarmingDataIds.length === 0;
    const isSelected = useCallback((alarmingDataId) => selectedAlarmingDataIds.includes(alarmingDataId), [selectedAlarmingDataIds]);
    const [unselectedAlarmingData, selectedAlarmingData] = useMemo(() => {
        const selectedAlarmingData = [];
        const unselectedAlarmingData = [];
        data.map((d) => {
            if (isSelected(d.id))
                return selectedAlarmingData.push(d);
            return unselectedAlarmingData.push(d);
        });
        return [unselectedAlarmingData, selectedAlarmingData];
    }, [data, isSelected]);
    const canConfirmSelection = (selectedAlarmingData === null || selectedAlarmingData === void 0 ? void 0 : selectedAlarmingData[0]) && isAlarmingDataConfirmationDisabled(selectedAlarmingData[0]);
    const handleSelectAll = () => selectedAlarmingDataIds.length < alarmingDataIds.length
        ? setSelectedAlarmingDataIds(alarmingDataIds)
        : setSelectedAlarmingDataIds([]);
    const handleSelect = (id) => selectedAlarmingDataIds.includes(id)
        ? setSelectedAlarmingDataIds(selectedAlarmingDataIds.filter((selectedId) => selectedId !== id))
        : setSelectedAlarmingDataIds([...selectedAlarmingDataIds, id]);
    const resetSelection = () => setSelectedAlarmingDataIds([]);
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        resetSelection();
        yield onConfirm(selectedAlarmingDataIds);
    });
    return (_jsxs("div", { className: cx(classes.container, className), children: [_jsxs("div", { className: classes.selectAll, children: [_jsx(Checkbox, { size: "small", indeterminate: !areAllSelected && !areNoneSelected, indeterminateIcon: _jsx(IconMinus, { className: classes.iconMinus }), checked: areAllSelected, onChange: handleSelectAll }), _jsx(Typography, { children: areNoneSelected
                            ? t('common.all')
                            : areAllSelected
                                ? t('common.unselect-all')
                                : `${t('common.selected')} (${selectedAlarmingDataIds.length})` }), !areNoneSelected && (_jsx(Button, { onClick: handleConfirm, disabled: canConfirmSelection || isLoading, className: classes.button, size: "small", color: "primary", children: isLoading ? (_jsx(CircularProgress, { size: 12, sx: { color: 'white' } })) : (t('buttons.confirm-selection')) }))] }), _jsxs("ul", { className: classes.dataList, children: [selectedAlarmingData.map((d) => {
                        var _a, _b, _c, _d;
                        return (_jsx("li", { className: classes.selectableData, children: _jsxs("div", { className: classes.infos, children: [_jsx(Checkbox, { size: "small", checked: isSelected(d.id), onChange: () => handleSelect(d.id) }), _jsx(AlarmingDataInfo, { id: d.id, title: scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD
                                            ? (_b = (_a = d === null || d === void 0 ? void 0 : d.farmSeasonField) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''
                                            : (_d = (_c = d === null || d === void 0 ? void 0 : d.farmSeasonFieldCrop) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '', values: d.values, showConfirm: false, onConfirm: handleConfirm, isConfirmDisabled: isAlarmingDataConfirmationDisabled(d), isLoading: isLoading })] }) }, d.id));
                    }), !areNoneSelected && (_jsx(AlarmingDataComments, { comments: [], alarmingDataIds: selectedAlarmingDataIds, onPostComment: resetSelection })), unselectedAlarmingData.map((d) => {
                        var _a, _b, _c, _d;
                        return (_jsxs("li", { className: classes.selectableData, children: [_jsxs("div", { className: classes.infos, children: [_jsx(Checkbox, { size: "small", checked: isSelected(d.id), onChange: () => handleSelect(d.id) }), _jsx(AlarmingDataInfo, { id: d.id, title: scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD
                                                ? (_b = (_a = d === null || d === void 0 ? void 0 : d.farmSeasonField) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''
                                                : (_d = (_c = d === null || d === void 0 ? void 0 : d.farmSeasonFieldCrop) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '', values: d.values, showConfirm: canConfirmAlarmingData(d), onConfirm: onConfirm, isConfirmDisabled: isAlarmingDataConfirmationDisabled(d), isLoading: isLoading })] }), _jsx(AlarmingDataComments, { comments: [], alarmingDataIds: [d.id], onPostComment: resetSelection })] }, d.id));
                    })] })] }));
};

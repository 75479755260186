import { makeStyles } from 'tss-react/mui';
export const usePillStyle = makeStyles()((theme) => ({
    root: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

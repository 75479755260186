var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMyProfileStyle from './MyProfile.style';
import { useForm } from 'react-hook-form';
import { useAuth } from '@hooks/useAuth';
import { LabelTextField } from '@components/LabelTextField';
import { useUpdateUserMutation } from '@services';
import { Colors } from '@utils/config';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { store } from '@/store';
import { useSnackbar } from 'notistack';
export const MyProfile = () => {
    var _a, _b;
    const { classes } = useMyProfileStyle();
    const { t } = useTranslation();
    const { user } = useAuth();
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const { enqueueSnackbar } = useSnackbar();
    const changeInfoFormSchema = yup.object({
        first_name: yup.string().required(t('validations.first-name-required')),
        last_name: yup.string().required(t('validations.last-name-required')),
    });
    const { register, handleSubmit, formState: { errors }, } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            first_name: user === null || user === void 0 ? void 0 : user.first_name,
            last_name: user === null || user === void 0 ? void 0 : user.last_name,
            email: user === null || user === void 0 ? void 0 : user.email,
        },
        resolver: yupResolver(changeInfoFormSchema),
    });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            yield updateUser({
                body: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                },
                userId: (_a = store.getState().auth.user) === null || _a === void 0 ? void 0 : _a.id,
            }).unwrap();
            enqueueSnackbar(t('snackbar.profile-updated-success'), { variant: 'success' });
        }
        catch (_err) {
            enqueueSnackbar(t('snackbar.profile-updated-error'), { variant: 'error' });
        }
    });
    return (_jsxs("div", { className: classes.container, children: [_jsx(Typography, { className: classes.subtitle, variant: "h3", color: Colors.newGrey, children: t('subtitles.my-profile') }), _jsx("div", { className: classes.avatar, children: _jsx(Typography, { variant: "h1", children: user ? (user.first_name.charAt(0) + user.last_name.charAt(0)).toUpperCase() : '' }) }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(LabelTextField, { InputProps: Object.assign({}, register('first_name', {
                            required: true,
                        })), autoComplete: "first_name", disabled: isLoading, error: errors.first_name !== undefined, helperText: (_a = errors.first_name) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.first-name'), variant: "outlined", fullWidth: true }), _jsx(LabelTextField, { InputProps: Object.assign({}, register('last_name', {
                            required: true,
                        })), autoComplete: "last_name", disabled: isLoading, error: errors.last_name !== undefined, helperText: (_b = errors.last_name) === null || _b === void 0 ? void 0 : _b.message, label: t('labels.last-name'), variant: "outlined", fullWidth: true }), _jsx(LabelTextField, { InputProps: Object.assign({}, register('email')), autoComplete: "email", disabled: true, label: t('labels.email'), variant: "outlined", fullWidth: true }), _jsx(Button, { type: "submit", variant: "contained", disableElevation: true, fullWidth: true, className: classes.updateBtn, children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.update') })] })] }));
};

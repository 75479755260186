import { createSlice } from '@reduxjs/toolkit';
export const FARMERS_SORTABLE_FIELDS = {
    COHORT: 'cohort',
    FULL_NAME: 'fullName',
    FARM_SEASON_STATUS: 'farmSeasonStatus',
};
const initialState = {
    season: undefined,
    cohorts: [],
    partners: [],
    contacts: [],
    userStatuses: [],
    farmSeasonStatuses: [],
    hiddenColumns: [],
    search: '',
    page: 0,
    sort: {
        field: FARMERS_SORTABLE_FIELDS.FULL_NAME,
        order: 'asc',
    },
};
export const farmersListSlice = createSlice({
    name: 'farmersList',
    initialState,
    reducers: {
        changeSeasons: (state, action) => {
            var _a;
            state.season = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.season;
        },
        changeCohorts: (state, action) => {
            var _a;
            state.cohorts = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.cohorts;
        },
        changeContacts: (state, action) => {
            var _a;
            state.contacts = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.contacts;
        },
        changePartners: (state, action) => {
            var _a;
            state.partners = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.partners;
        },
        changeUserStatuses: (state, action) => {
            var _a;
            state.userStatuses = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.userStatuses;
        },
        changeFarmSeasonStatuses: (state, action) => {
            var _a;
            state.farmSeasonStatuses = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.farmSeasonStatuses;
        },
        changeSearch: (state, action) => {
            var _a;
            state.search = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.search;
        },
        changePage: (state, action) => {
            var _a;
            state.page = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.page;
        },
        changeSorting: (state, action) => {
            var _a;
            state.sort = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.sortModel;
        },
        changeHiddenColumns: (state, action) => {
            var _a;
            state.hiddenColumns = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.hiddenColumns;
        },
    },
});
export const selectFarmersListSeasons = (state) => state.farmersList.season;
export const selectFarmersListCohorts = (state) => state.farmersList.cohorts;
export const selectFarmersListPartners = (state) => state.farmersList.partners;
export const selectFarmersListContacts = (state) => state.farmersList.contacts;
export const selectFarmersListUserStatuses = (state) => state.farmersList.userStatuses;
export const selectFarmersListFarmSeasonStatuses = (state) => state.farmersList.farmSeasonStatuses;
export const selectFarmersListSearch = (state) => state.farmersList.search;
export const selectFarmersListPage = (state) => state.farmersList.page;
export const selectFarmersListSortingOptions = (state) => state.farmersList.sort;
export const selectFarmersListHiddenColumns = (state) => state.farmersList.hiddenColumns;
export const { changeSeasons, changeCohorts, changePartners, changeContacts, changeUserStatuses, changeFarmSeasonStatuses, changeSearch, changePage, changeSorting, changeHiddenColumns, } = farmersListSlice.actions;
export const farmersListReducer = farmersListSlice.reducer;

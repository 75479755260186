import { jsx as _jsx } from "react/jsx-runtime";
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@router/routes';
import { Box } from '@mui/material';
import BreadcrumbsNavigation from '@features/navigation/BreadcrumbsNavigation';
export const FarmerProfileTopNav = () => {
    const navigate = useNavigate();
    const navigateToPrevious = () => navigate(ROUTES.FARMERS);
    const breadcrumbsItems = [
        {
            label: t('titles.all-farmers'),
            onClick: navigateToPrevious,
        },
        {
            label: t('titles.farmer-profile'),
            active: true,
        },
    ];
    return (_jsx("header", { children: _jsx(Box, { mb: "18px", children: _jsx(BreadcrumbsNavigation, { items: breadcrumbsItems, back: navigateToPrevious }) }) }));
};

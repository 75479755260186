import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { LabelTextField } from '@components/LabelTextField';
import { RequiredInputLabel } from '@components/RequiredInputLabel';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { getHookFormErrorMessage } from '@utils/string';
import { SelectLanguages } from '@components/SelectLanguages/SelectLanguages';
import { useComposeEmailFormStyles } from './ComposeEmailForm.style';
import { useGetEmailConfigurations } from '@features/invite/farmer/useGetEmailConfigurations';
import { useGetSupportedInvitationLanguagesQuery } from '@services';
import { useEffect } from 'react';
export const ComposeEmailForm = () => {
    const { t, i18n } = useTranslation();
    const { classes } = useComposeEmailFormStyles();
    const { data: languages } = useGetSupportedInvitationLanguagesQuery();
    const { data: emailConfigs } = useGetEmailConfigurations();
    const { setValue, watch } = useFormContext();
    const selectedLanguageId = watch('languageId');
    useEffect(() => {
        if (languages) {
            const initialLanguage = languages === null || languages === void 0 ? void 0 : languages.find((l) => l.iso_code === i18n.language);
            setValue('languageId', initialLanguage === null || initialLanguage === void 0 ? void 0 : initialLanguage.id);
            setValue('languageCode', initialLanguage === null || initialLanguage === void 0 ? void 0 : initialLanguage.iso_code);
        }
    }, [languages]);
    const { register, formState: { errors }, } = useFormContext();
    const subjectErrorMessage = getHookFormErrorMessage(errors === null || errors === void 0 ? void 0 : errors.subject);
    const bodyErrorMessage = getHookFormErrorMessage(errors === null || errors === void 0 ? void 0 : errors.body);
    const handleLanguageChange = (language) => {
        setValue('languageId', language.id);
        setValue('languageCode', language.iso_code);
        const emailConfigForCurrentLanguage = emailConfigs === null || emailConfigs === void 0 ? void 0 : emailConfigs.find((c) => (c === null || c === void 0 ? void 0 : c.language_id) === language.id);
        if (emailConfigForCurrentLanguage) {
            setValue('subject', emailConfigForCurrentLanguage === null || emailConfigForCurrentLanguage === void 0 ? void 0 : emailConfigForCurrentLanguage.email_subject);
            setValue('body', emailConfigForCurrentLanguage === null || emailConfigForCurrentLanguage === void 0 ? void 0 : emailConfigForCurrentLanguage.email_body);
        }
    };
    if (!languages || !selectedLanguageId)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { width: "50%", children: [_jsx(RequiredInputLabel, { required: true, className: classes.inputTitle, children: t('labels-language') }), _jsx(SelectLanguages, { languageId: selectedLanguageId, languages: languages, onChange: handleLanguageChange })] }), _jsxs(Box, { children: [_jsx(LabelTextField, { inputProps: Object.assign({}, register('subject')), label: t('teams.invite.email-subject'), required: true, fullWidth: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.subject), helperText: subjectErrorMessage }), _jsx(LabelTextField, { inputProps: Object.assign({}, register('body')), label: t('teams.invite.email-content'), required: true, multiline: true, minRows: 5, maxRows: 5, fullWidth: true, error: !!(errors === null || errors === void 0 ? void 0 : errors.body), helperText: bodyErrorMessage })] })] }));
};

import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileInfosStyle = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
        },
        visitFarmContainer: {
            position: 'absolute',
            top: theme.spacing(6),
            right: theme.spacing(6),
        },
        personalInfosContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneEmailContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
        },
        emailPhone: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        farmerProfesionalInfosContainer: {
            display: 'flex',
            gap: theme.spacing(40),
            justifyContent: 'center',
            marginTop: theme.spacing(10),
        },
        farmerProfesionalInfosItem: {
            display: 'flex',
            gap: theme.spacing(2),
        },
        contact: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
    };
});

import { IconCheckboxChecked, IconCheckbox } from '@components/Icons';
import React from 'react';
const CheckboxCss = {
    root: ({ theme }) => ({
        '&.MuiCheckbox-indeterminate': {
            fill: '#b07c48',
            '& svg': {
                backgroundColor: '#F9F5E9',
                borderRadius: '5px',
                border: `1px solid #b07c48`,
            },
        },
        '&.Mui-checked': {
            '& svg': {
                fill: theme.palette.success.dark,
            },
        },
        '& svg': {
            width: 24,
            height: 24,
            borderRadius: '5px',
            border: `1px solid ${theme.palette.grey['300']}`,
        },
    }),
};
const CheckboxProperties = {
    color: 'primary',
    checkedIcon: React.createElement(IconCheckboxChecked),
    icon: React.createElement(IconCheckbox),
};
const MuiCheckbox = { styleOverrides: CheckboxCss, defaultProps: CheckboxProperties };
export default MuiCheckbox;

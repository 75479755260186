import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CollapsableCard } from '@components/CollapsableCard/CollapsableCard';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCollapsableStyles from '../Collapsable.style';
import { DashboardStep } from '../Step/DashboardStep';
import { useOnboardingStepsViewModel } from '@features/farmer/profile/components/FarmerDashboard/Onboarding/useOnboardingStepsViewModel';
import { usePermission } from '@hooks/usePermission';
export const OnboardingCollapsable = ({ isExpanded = false, profile }) => {
    const { canAccessPlatformAsAssociate } = usePermission();
    const { classes } = useCollapsableStyles();
    const { t } = useTranslation();
    const { currentStep, maxSteps, invitedStatus, accountCreatedStatus, registeredStatus } = useOnboardingStepsViewModel({
        profile,
    });
    return (_jsxs(CollapsableCard, { isExpanded: isExpanded, header: t('labels.onboarding'), currentStep: currentStep, maxSteps: maxSteps, children: [!canAccessPlatformAsAssociate && (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: _jsx(MailOutlineIcon, { className: classes.icon }), stepStatus: invitedStatus }), _jsx(Box, { className: classes.stepConnector })] })), _jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: _jsx(AccountCircleOutlinedIcon, { className: classes.icon }), stepStatus: accountCreatedStatus }), _jsx(Box, { className: classes.stepConnector })] }), _jsx(DashboardStep, { icon: _jsx(PersonAddAltIcon, { className: classes.icon }), stepStatus: registeredStatus })] }));
};

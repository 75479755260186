import { usePermission } from '@hooks/usePermission';
import { skipToken } from '@reduxjs/toolkit/query';
import { ApiToggle, useGetFarmerFullProfileQuery } from '@services';
import { getAvatarInitials } from '@utils/string';
// TODO remove
export const useFarmerProfile = ({ id }) => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const partnerCanFetchProfile = (canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers) && !!id;
    const associateCanFetchProfile = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && !!id;
    const fetchParam = partnerCanFetchProfile && id
        ? { toggle: ApiToggle.PARTNER, invitationId: id }
        : associateCanFetchProfile && id
            ? { toggle: ApiToggle.ASSOCIATE, userId: id }
            : skipToken;
    const { data, isLoading, isFetching, refetch } = useGetFarmerFullProfileQuery(fetchParam);
    const result = data
        ? Object.assign(Object.assign({}, data), { initials: getAvatarInitials(data === null || data === void 0 ? void 0 : data.first_name, data === null || data === void 0 ? void 0 : data.last_name, data === null || data === void 0 ? void 0 : data.email) }) : undefined;
    return {
        data: result,
        isLoading: isLoading || isFetching,
        refetch,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Modal, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConfirmFarmerRemovalModalStyles } from '@features/farmer/delete/ConfirmFarmerRemovalModal.style';
import { useDeleteFarmer } from '@features/farmer/delete/useDeleteFarmer';
import { ROUTES } from '@router/routes';
export const MODAL_TEST_ID = 'confirm-farmer-removal-modal';
export const ConfirmFarmerRemovalModal = ({ farmerId, invitationId, open, onClose }) => {
    const { classes } = useConfirmFarmerRemovalModalStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleDeleteSuccess = () => {
        navigate(ROUTES.FARMERS);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const { onDelete, isLoading } = useDeleteFarmer({ farmerId, invitationId, onSuccess: handleDeleteSuccess });
    return (_jsx(Modal, { open: open, onClose: onClose, "data-testid": MODAL_TEST_ID, "aria-labelledby": "Confirm-Farmer-Removal-Settings", "aria-describedby": "Confirm-Farmer-Removal-Settings-Modal", children: _jsxs(Box, { className: classes.contentContainer, children: [_jsx(Typography, { align: "center", variant: "h2", className: classes.title, children: t('titles.farmer-confirm-removal-modal') }), _jsx(Typography, { variant: "subtitle1", className: classes.title, children: t('subtitles.farmer-confirm-removal') }), _jsxs(Box, { display: "flex", justifyContent: "flex-end", className: classes.buttonsContainer, children: [_jsx(Button, { onClick: onClose, variant: "outlined", className: classes.cancelBtn, children: t('buttons.cancel') }), _jsx(Button, { color: "error", onClick: onDelete, startIcon: _jsx(DeleteIcon, {}), children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.remove') })] })] }) }));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useInviteFarmersModalStyles } from '@features/invite/farmer/Modal/InviteFarmersModal.style';
import { InputChips } from '@components/Form/InputChips/InputChips';
import { getHookFormErrorMessage } from '@utils/string';
import { RequiredInputLabel } from '@components/RequiredInputLabel';
import { useCreateValidationSchema } from '@features/invite/farmer/useInviteFarmerModalForm';
import { useCheckIfPartnerCanInviteFarmerMutation } from '@services/invitation.api';
export const SelectFarmersEmailsForm = () => {
    const { t } = useTranslation();
    const { classes } = useInviteFarmersModalStyles();
    const { emailValidationSchema } = useCreateValidationSchema(t);
    const [checkCanInviteFarmer, { isLoading: isLoadingCheckAlreadyInvited }] = useCheckIfPartnerCanInviteFarmerMutation();
    const isLoading = isLoadingCheckAlreadyInvited;
    const { setValue, watch, setError, clearErrors, formState: { errors }, } = useFormContext();
    const emailError = getHookFormErrorMessage(errors === null || errors === void 0 ? void 0 : errors.emails);
    const handleInputChange = () => {
        clearErrors('emails');
    };
    const handleEmailsChange = (chips) => {
        setValue('emails', chips.map((chip) => chip.value));
    };
    const handleEmailDeleted = (chip) => {
        const currentEmails = watch('emails');
        const updatedEmails = currentEmails === null || currentEmails === void 0 ? void 0 : currentEmails.filter((email) => email !== chip.value);
        setValue('emails', updatedEmails);
    };
    const handleReset = () => {
        setValue('emails', []);
    };
    const isChipValueCorrect = (value) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!emailValidationSchema.validateSync(value))
                return false;
            const currentEmails = watch('emails');
            if (currentEmails.includes(value)) {
                setError('emails', { message: t(`farmers.invite.errors.duplicated-email`) });
                return false;
            }
            const res = yield checkCanInviteFarmer({
                email: value,
            }).unwrap();
            if (res === null || res === void 0 ? void 0 : res.reason)
                setError('emails', { message: t(`farmers.invite.errors.${res.reason}`) });
            return res.canInviteFarmer;
        }
        catch (error) {
            setError('emails', { message: error === null || error === void 0 ? void 0 : error.message });
            return false;
        }
    });
    return (_jsxs(Box, { children: [_jsx(RequiredInputLabel, { required: true, className: classes.inputTitle, children: t('farmers.invite.recipients') }), _jsx(InputChips, { fullWidth: true, onChange: handleEmailsChange, onInputChange: handleInputChange, error: !!(errors === null || errors === void 0 ? void 0 : errors.emails), helperText: emailError, validate: isChipValueCorrect, onChipDelete: handleEmailDeleted, onReset: handleReset, placeholder: t('farmers.invite.add-emails'), maxChips: 2, remainingChipsLabel: t('common.others'), isLoading: isLoading })] }));
};

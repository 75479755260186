import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
let appInsights;
let reactPlugin;
const createTelemetryService = (browserHistory) => {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: `${process.env.AZURE_INSIGHTS_KEY}`,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
            enableAutoRouteTracking: false, // automatically done with the react SDK
            disableFetchTracking: false,
            loggingLevelTelemetry: 2,
            loggingLevelConsole: 2,
        },
    });
    appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ 'ai.cloud.role': 'PartnerPortalWeb' });
    });
    appInsights.loadAppInsights();
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getAppInsights = () => appInsights;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getReactPlugin = () => reactPlugin;
export default {
    createTelemetryService,
    getAppInsights,
    getReactPlugin,
};

import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileTimelineStyle = makeStyles()((theme) => {
    return {
        container: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        farmSeasonInfosContainer: {
            position: 'relative',
            marginTop: theme.spacing(20),
            borderTop: `2px solid ${theme.palette.success.dark}`,
            overflow: 'visible',
        },
        tooltipTriangle: {
            position: 'absolute',
            top: -24,
            left: 100,
            background: theme.palette.grey[50],
            width: 24,
            aspectRatio: 1,
            clipPath: 'polygon(50% 20%, 0% 100%, 100% 100%)',
        },
    };
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconCross } from '@components/Icons';
import { Button, Typography } from '@mui/material';
import { useClearAllFilterStyle } from '@features/farmer/listFarmers/Filters/ClearAllFilter/ClearAllFilter.style';
import { useTranslation } from 'react-i18next';
export const ClearAllFilter = ({ onClear }) => {
    const { classes } = useClearAllFilterStyle();
    const { t } = useTranslation();
    return (_jsxs(Button, { className: classes.root, onClick: onClear, children: [_jsx(IconCross, { className: classes.cross }), _jsx(Typography, { children: t('common.clear-all-filter') })] }));
};

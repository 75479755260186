import { api } from './api.service';
export const seasonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSeasons: builder.query({
            query: () => ({
                url: `/private/seasons`,
                method: 'GET',
            }),
        }),
        verifyEncoding: builder.mutation({
            query: ({ farmSeasonId }) => ({
                url: `${process.env.FARMER_PORTAL_API}/v3/farm-seasons/${farmSeasonId}/verify-encoding`,
                method: 'POST',
            }),
        }),
    }),
});
export const { useGetSeasonsQuery, useVerifyEncodingMutation } = seasonApi;

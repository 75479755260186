import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Select } from '@components/Select';
import { useFarmSeasonStatuses } from '@features/farmer/listFarmers/Filters/FarmSeasonStatusFilter/useFarmSeasonStatuses';
export const FarmSeasonStatusFilter = ({ values, onChange }) => {
    const { t } = useTranslation();
    const farmSeasonStatuses = useFarmSeasonStatuses();
    const handleChange = (values) => onChange(values);
    const getOptionLabel = (farmSeasonStatus) => farmSeasonStatus;
    const getOptionValue = (farmSeasonStatus) => farmSeasonStatus;
    return (_jsx(Select, { values: values !== null && values !== void 0 ? values : [], onChange: handleChange, options: farmSeasonStatuses, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (farmSeasonStatuses, allAreSelected) => (_jsx(Select.LabelWithPill, { label: t('teams.grid.columns.farm-season-status'), selected: farmSeasonStatuses.length, areAllSelected: allAreSelected })), renderOption: (option, props) => (_jsx(Select.CheckboxOption, Object.assign({}, props, { label: t(`statuses.farm-season.${option}`) }))), renderOptionAll: (props) => _jsx(Select.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};

import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        headerContainer: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        steps: {
            marginLeft: theme.spacing(3),
            color: '#BDBDBD',
        },
        skeleton: {},
    };
});

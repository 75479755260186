export const REMAINING_CHIPS_WIDTH_IN_PERCENT = 20;
export const useSelectedChips = ({ chips, maxChips, availableWidthForChipsPercentage = 100, }) => {
    const chipsToDisplay = chips.slice(0, maxChips || chips.length);
    const remainingChipsCount = chips.length - chipsToDisplay.length;
    const availableWidthForChips = remainingChipsCount
        ? // -20 to leave space for the +X ... message
            availableWidthForChipsPercentage - REMAINING_CHIPS_WIDTH_IN_PERCENT
        : availableWidthForChipsPercentage;
    const maxCalculatedWithForAChip = maxChips
        ? `calc(${availableWidthForChips}%/${maxChips > chipsToDisplay.length ? chipsToDisplay.length : maxChips})`
        : 'auto';
    const remainingChips = maxChips ? chips.slice(maxChips, chips.length) : [];
    return { chipsToDisplay, maxCalculatedWithForAChip, remainingChips };
};

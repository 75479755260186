import { jsx as _jsx } from "react/jsx-runtime";
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useSearchInputStyle } from './SearchInput.style';
export const SearchInput = ({ defaultValue = '', placeholder, onChange, onReset }) => {
    const [value, setValue] = useState(defaultValue);
    const { classes } = useSearchInputStyle();
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };
    const handleReset = () => {
        setValue('');
        onReset === null || onReset === void 0 ? void 0 : onReset();
    };
    return (_jsx(TextField, { className: classes.textField, value: value, onChange: handleChange, InputProps: {
            placeholder,
            startAdornment: _jsx(SearchIcon, { color: "primary", className: classes.searchIcon }),
            endAdornment: value ? (_jsx(CloseIcon, { onClick: handleReset, className: classes.resetIcon, color: "primary" })) : (_jsx(Box, { sx: { width: 16 } })),
        } }));
};

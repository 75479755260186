import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import notFoundImg from '@images/not_found.png';
import { AppLayout } from '@/layouts/AppLayout/AppLayout';
import { getHasExistingToken } from '@utils/accessTokenCookie';
import { ConditionalWrapper } from '@components/ConditionalWrapper/ConditionalWrapper';
import { useUserQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
export const NotFoundPage = () => {
    const { hasAccessTokenCookie } = getHasExistingToken();
    const { data: user, isLoading } = useUserQuery(hasAccessTokenCookie ? undefined : skipToken);
    if (isLoading)
        return _jsx(_Fragment, {});
    return (_jsx(ConditionalWrapper, { condition: !!user || hasAccessTokenCookie, wrapper: (children) => _jsx(AppLayout, { children: children }), children: _jsx("img", { alt: "Not found", src: notFoundImg, style: { maxWidth: '90vw', maxHeight: '90vh', margin: 'auto', display: 'block' } }) }));
};

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFarmerProfileTimelineStyle } from './FarmerProfileTimeline.style';
import { useEffect, useState } from 'react';
import { useGetFarmerProfileFarmSeasons } from '@features/farmer/profile/hooks/useGetFarmerProfileFarmSeasons';
import { FARM_SEASON_STATUS } from '@/types/farmSeason.types';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material';
import { Timeline, TimelineStep, TimelineTrigger, TimelineNode, TimelineLabel, TimelineSegment, TIMELINE_STEP_STATUS, } from '@components/Timeline';
export const FarmerProfileTimeline = () => {
    var _a;
    const { classes } = useFarmerProfileTimelineStyle();
    const { t } = useTranslation();
    const [selectedFarmSeasonId, setSelectedFarmSeasonId] = useState(undefined);
    const { data: farmSeasons, isLoading } = useGetFarmerProfileFarmSeasons();
    const currentFarmSeason = (_a = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.find((fs) => fs.status !== FARM_SEASON_STATUS.RESULTS_PUBLISHED)) === null || _a === void 0 ? void 0 : _a.id;
    useEffect(() => {
        if (farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.length) {
            currentFarmSeason
                ? setSelectedFarmSeasonId(currentFarmSeason)
                : setSelectedFarmSeasonId(farmSeasons[farmSeasons.length - 1].id);
        }
    }, [farmSeasons]);
    if (isLoading)
        return _jsx(_Fragment, {});
    const steps = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.map((farmSeason, index) => ({
        id: (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id) ? Number(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id) : undefined,
        isSelected: farmSeason.id ? selectedFarmSeasonId === Number(farmSeason.id) : false,
        label: index === 0
            ? capitalize(`${t('common.baseline-season')} \n ${farmSeason.harvest_year}`)
            : capitalize(`${t('common.season')} ${index} \n ${farmSeason.harvest_year}`),
        status: farmSeason.status === FARM_SEASON_STATUS.RESULTS_PUBLISHED
            ? TIMELINE_STEP_STATUS.DONE
            : farmSeason.status !== null
                ? TIMELINE_STEP_STATUS.IN_PROGRESS
                : TIMELINE_STEP_STATUS.NOT_STARTED,
        onClick: (id) => setSelectedFarmSeasonId(id),
        disabled: !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id),
    }));
    if (!steps)
        return _jsx(_Fragment, {});
    return (_jsx("div", { className: classes.container, children: _jsx(Timeline, { selectedStep: selectedFarmSeasonId, onStepSelect: (value) => setSelectedFarmSeasonId(value), children: steps.map((step, index) => {
                var _a, _b;
                return (_jsxs(TimelineStep, { value: (_a = step.id) !== null && _a !== void 0 ? _a : index, status: step.status, children: [_jsxs(TimelineTrigger, { disabled: step.disabled, children: [_jsx(TimelineNode, {}), _jsx(TimelineLabel, { children: step.label })] }), index < steps.length - 1 && _jsx(TimelineSegment, {})] }, (_b = step.id) !== null && _b !== void 0 ? _b : index));
            }) }) }));
};

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { IconEdit } from '@components/Icons';
import { useAssignContactButtonStyles } from '@features/assignment/AssignContactButton/AssignContactButton.style';
import { useAssignedContactsFormData } from '@features/assignment/useAssignedContactsFormData';
export const AssignContactButton = ({ farmerId, onClick }) => {
    const { classes } = useAssignContactButtonStyles();
    const { canEditAssignedContacts } = useAssignedContactsFormData({ farmerId });
    if (!canEditAssignedContacts)
        return _jsx(_Fragment, {});
    return (_jsx(Button, { "data-testid": "edit-contacts", onClick: onClick, className: classes.editButton, variant: "text", children: _jsx(IconEdit, { className: classes.editIcon }) }));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { capitalize, Checkbox, FormControl, MenuItem, Select as MuiSelect, Typography, } from '@mui/material';
import { useSelectStyle } from '@components/Select/Select.style';
import { Pill } from '@components/Pill';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Select = (props) => {
    const { classes } = useSelectStyle();
    const { label, value, values, options, onChange, getOptionLabel, getOptionKey: getOptionKeyProp, getOptionValue, className, renderValue, renderOption, MenuProps } = props, rest = __rest(props, ["label", "value", "values", "options", "onChange", "getOptionLabel", "getOptionKey", "getOptionValue", "className", "renderValue", "renderOption", "MenuProps"]);
    if (value && values)
        throw new Error('Select component - should provide either value or values as prop, not both');
    if (Array.isArray(value))
        throw new Error('Select component - value prop should not be an array');
    const isMultiple = 'values' in props;
    const getOptionKey = getOptionKeyProp ? getOptionKeyProp : getOptionValue;
    const selectedValue = value ? getOptionValue(value) : values === null || values === void 0 ? void 0 : values.map((v) => getOptionValue(v));
    const optionValueLookup = {};
    options.map((option) => {
        const optionValue = getOptionValue(option);
        optionValueLookup[optionValue] = option;
    });
    const convertSelectedValueToOptionObject = (value) => optionValueLookup[value];
    const handleChange = (event) => {
        if (typeof values !== 'undefined') {
            const selectedValues = event.target.value;
            const convertedValues = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map((v) => convertSelectedValueToOptionObject(v));
            return onChange(convertedValues);
        }
        const selectedValue = event.target.value;
        const convertedValue = convertSelectedValueToOptionObject(selectedValue);
        onChange(convertedValue);
    };
    const renderInputValue = (selectedValues) => {
        if (typeof values !== 'undefined') {
            const selectedOptions = selectedValues.map((value) => optionValueLookup[value]);
            const selectedValuesLabels = selectedOptions.map((option) => ({
                key: getOptionKey(option),
                value: getOptionLabel(option),
            }));
            return renderValue
                ? renderValue(selectedOptions, selectedOptions.length === options.length)
                : selectedValuesLabels.map((label) => label.value).join(', ');
        }
        const selectedOption = optionValueLookup[selectedValue];
        const selectedValuesLabels = {
            key: getOptionKey(selectedOption),
            value: getOptionLabel(selectedOption),
        };
        return renderValue ? renderValue(selectedOption) : selectedValuesLabels.value;
    };
    const handleAllSelected = () => {
        if (typeof values !== 'undefined') {
            return Array.isArray(selectedValue) && options.length === (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.length)
                ? onChange([])
                : onChange(options);
        }
    };
    const renderOptionAll = props.renderOptionAll
        ? props.renderOptionAll({
            onClick: handleAllSelected,
            isSelected: Array.isArray(selectedValue) && options.length === (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.length),
        })
        : undefined;
    return (_jsx(FormControl, { className: className, children: _jsxs(MuiSelect, Object.assign({ label: label, value: selectedValue, onChange: handleChange, renderValue: renderInputValue, multiple: isMultiple, displayEmpty: true, IconComponent: ExpandMoreIcon, MenuProps: Object.assign({ PopoverClasses: {
                    paper: classes.menuPaper,
                } }, MenuProps) }, rest, { children: [renderOptionAll, options.map((option) => renderOption ? (renderOption(option, {
                    key: getOptionKey(option),
                    value: getOptionValue(option),
                })) : (_jsx(Select.Option, { value: getOptionValue(option), children: getOptionLabel(option) }, getOptionKey(option))))] })) }));
};
const Option = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return _jsx(MenuItem, Object.assign({}, rest, { children: children }));
};
const CheckboxOption = (_a) => {
    var { selected, label } = _a, rest = __rest(_a, ["selected", "label"]);
    const { classes } = useSelectStyle();
    return (_jsxs(MenuItem, Object.assign({ className: classes.checkboxOption }, rest, { children: [_jsx(Checkbox, { className: classes.checkbox, size: "small", checked: selected }), label] })));
};
const LabelWithPill = ({ label, selected, areAllSelected, }) => {
    const { classes } = useSelectStyle();
    const capitalizedLabel = capitalize(label);
    return (_jsxs("div", { className: classes.valuesContainer, children: [capitalizedLabel, ": ", selected > 0 && (areAllSelected ? 'All' : _jsx(Pill, { children: selected }))] }));
};
const OptionAll = ({ label, onClick, isSelected }) => {
    const { classes } = useSelectStyle();
    return (_jsxs("div", { className: classes.optionAll, onClick: onClick, children: [_jsx(Checkbox, { className: classes.checkbox, size: "small", checked: isSelected }), _jsx(Typography, { children: label })] }));
};
Select.Option = Option;
Select.CheckboxOption = CheckboxOption;
Select.LabelWithPill = LabelWithPill;
Select.OptionAll = OptionAll;
export { Select };

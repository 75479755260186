import { makeStyles } from 'tss-react/mui';
export const useClearAllFilterStyle = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        background: 'none',
        border: 'none',
        paddingLeft: theme.spacing(1),
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover, &:focus': {
            background: 'none',
            border: 'none',
        },
    },
    cross: {
        width: 12,
    },
}));

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Select } from '@components/Select';
import { useGetMembers } from '@hooks/useGetMembers';
export const ContactFilter = ({ values, onChange }) => {
    const { t } = useTranslation();
    const { data: members } = useGetMembers();
    const membersWithoutPending = members === null || members === void 0 ? void 0 : members.filter((member) => !!(member === null || member === void 0 ? void 0 : member.user_id));
    const handleChange = (values) => onChange(values);
    const getOptionLabel = (member) => `${member === null || member === void 0 ? void 0 : member.first_name} ${member === null || member === void 0 ? void 0 : member.last_name}`;
    const getOptionValue = (member) => member.id;
    if (!membersWithoutPending)
        return _jsx(_Fragment, {});
    return (_jsx(Select, { values: values !== null && values !== void 0 ? values : [], onChange: handleChange, options: membersWithoutPending, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (members, allAreSelected) => (_jsx(Select.LabelWithPill, { label: t('common.contact'), selected: members.length, areAllSelected: allAreSelected })), MenuProps: { autoFocus: false }, renderOption: (option, props) => (_jsx(Select.CheckboxOption, Object.assign({}, props, { label: `${option.first_name} ${option.last_name}` }))), renderOptionAll: (props) => _jsx(Select.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};

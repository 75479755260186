var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { theme } from './theme';
import '@assets/css/index.css';
import App from './App';
import ai from './appInsights';
import './i18n';
import { store } from './store';
const history = createBrowserHistory();
ai.createTelemetryService(history);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(AppInsightsErrorBoundary, { appInsights: ai.getReactPlugin(), onError: () => _jsx("p", { children: "An unexpected error occured" }), children: _jsx(IntercomProvider, { appId: (_a = process.env.REACT_APP_INTERCOM_APP_KEY) !== null && _a !== void 0 ? _a : '', autoBoot: true, children: _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsxs(StyledEngineProvider, { injectFirst: true, children: [_jsx(CssBaseline, {}), _jsx(ThemeProvider, { theme: theme, children: _jsx(SnackbarProvider, { maxSnack: 3, children: _jsx(App, {}) }) })] }) }) }) }) }) }));

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import useSettingsStyle from './SettingsSection.style';
import { useTranslation } from 'react-i18next';
import { MyProfile } from './MyProfile/MyProfile';
import { ChangePassword } from './ChangePassword/ChangePassword';
const SettingsSection = () => {
    const { classes } = useSettingsStyle();
    const { t } = useTranslation();
    return (_jsxs("div", { className: classes.container, children: [_jsx(Typography, { variant: "h3", color: "secondary", children: t('titles.settings') }), _jsxs("div", { className: classes.contentLayout, children: [_jsx(MyProfile, {}), _jsx(ChangePassword, {})] })] }));
};
export default SettingsSection;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAlarmingDataCommentsStyles } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataComments.style';
import { Avatar } from '@components/Avatar';
import { getAvatarInitials } from '@utils/string';
import { Typography } from '@mui/material';
import { AlarmingDataCommentForm } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataCommentForm/AlarmingDataCommentForm';
import { useState } from 'react';
import { IconEdit, IconTrash } from '@components/Icons';
import { useAppSelector } from '@hooks';
import { selectCurrentUser } from '@features/auth';
import { useDeleteCommentMutation } from '@services';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const Comment = ({ comment, onEdit, onDelete }) => {
    const { classes } = useAlarmingDataCommentsStyles();
    const { author, value } = comment;
    const initials = getAvatarInitials(author.firstName, author.lastName);
    const user = useAppSelector(selectCurrentUser);
    return (_jsxs("div", { className: classes.comment, children: [_jsx(Avatar, { children: initials }), _jsxs(Typography, { className: classes.author, children: [author.firstName, " ", author.lastName, ":"] }), _jsx(Typography, { children: value }), (user === null || user === void 0 ? void 0 : user.id) === comment.author.id && (_jsxs(_Fragment, { children: [_jsx(IconEdit, { onClick: () => onEdit === null || onEdit === void 0 ? void 0 : onEdit(comment.id), className: classes.icon, color: "secondary" }), _jsx(IconTrash, { onClick: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(comment.id), className: classes.icon, color: "secondary" })] }))] }));
};
export const AlarmingDataComments = ({ comments, showForm = true, alarmingDataIds, onPostComment, }) => {
    const [editableComment, setEditableComment] = useState();
    const { classes } = useAlarmingDataCommentsStyles();
    const { t } = useTranslation();
    const [deleteComment] = useDeleteCommentMutation();
    const { enqueueSnackbar } = useSnackbar();
    const handleEditClicked = (commentId) => {
        setEditableComment(commentId);
    };
    const handleDeleteClicked = (commentId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield deleteComment({ commentId }).unwrap();
            enqueueSnackbar(t('snackbar.comment-deleted'), { variant: 'success' });
        }
        catch (_e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const handleBack = () => setEditableComment(null);
    const handleSubmit = () => {
        setEditableComment(null);
        onPostComment === null || onPostComment === void 0 ? void 0 : onPostComment();
    };
    return (_jsxs("div", { className: classes.list, children: [comments.map((comment) => comment.id === editableComment ? (_jsx(AlarmingDataCommentForm, { commentId: comment.id, defaultValue: comment.value, alarmingDataIds: alarmingDataIds, onSubmit: handleSubmit, onBack: handleBack }, comment.id)) : (_jsx(Comment, { comment: comment, onEdit: handleEditClicked, onDelete: handleDeleteClicked }, comment.id))), showForm && (_jsx(AlarmingDataCommentForm, { alarmingDataIds: alarmingDataIds, onSubmit: handleSubmit, onBack: handleBack }))] }));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyGetAlarmingDataProcessQuery } from '@services/alarmingData.api';
import { useGenerateResultsV2Mutation, useGenerateResultsV3Mutation } from '@services/results.api';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useVerifyEncodingMutation } from '@services/season.api';
export const useGenerateResults = ({ carbonResultModel, isBaseLine, carbonProgrammeSlug, onSuccess, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [generateResultsV2, { isLoading: isGenerateResultsV2Loading }] = useGenerateResultsV2Mutation();
    const [generateResultsV3, { isLoading: isGenerateResultsV3Loading }] = useGenerateResultsV3Mutation();
    const [verifyEncoding, { isLoading: isVerifyingEncodingLoading }] = useVerifyEncodingMutation();
    const [getAlarmingDataProcess, { isLoading: isAlarmingDataProcessLoading }] = useLazyGetAlarmingDataProcessQuery();
    const generateResults = (farmSeason, farmId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            yield getAlarmingDataProcess({
                farmSeasonId: Number(farmSeason),
                processHistory: isBaseLine,
            }).unwrap();
            yield verifyEncoding({ farmSeasonId: Number(farmSeason) }).unwrap();
            if (carbonProgrammeSlug !== 'carbon-programme-4') {
                if (carbonResultModel === CARBON_RESULT_MODEL.DNDC) {
                    yield generateResultsV3({ farmSeasonId: Number(farmSeason) }).unwrap();
                }
                else {
                    yield generateResultsV2({ farmSeasonId: Number(farmSeason), farmId: Number(farmId) }).unwrap();
                }
            }
            enqueueSnackbar(t('snackbar.encoding-validated'), {
                variant: 'default',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (e) {
            console.log(e);
            // This is a workaround to tell the issue to the user. The button should not be clickable if the encoding is not finished.
            const encodingNotFinishedMessages = [
                'The encoding in V3 is not finished yet for this farm_season',
                'The encoding in V2 is not finished yet for this farm_season',
            ];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (encodingNotFinishedMessages.includes((_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message)) {
                enqueueSnackbar(t('errors.farmer-profile-encoding-not-finished'), { variant: 'error' });
                console.error('The encoding in V3 is not finished yet for the farm_season: ', farmSeason);
                return;
            }
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const isLoading = isGenerateResultsV2Loading ||
        isGenerateResultsV3Loading ||
        isAlarmingDataProcessLoading ||
        isVerifyingEncodingLoading;
    return { generateResults, isLoading };
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { useFarmerProfileAboutCardStyles } from '@features/farmer/profile/components/FarmerProfileAboutCard/FarmerProfileAboutCard.style';
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
import { AssignContactButton } from '@features/assignment/AssignContactButton/AssignContactButton';
import { ContactForm } from '@features/assignment/ContactForm/ContactForm';
import { ContactList } from '@features/assignment/ContactList/ContactList';
import { useToggle } from '@hooks/useToggle';
import { useAssignedContactsFormData } from '@features/assignment/useAssignedContactsFormData';
import { usePermission } from '@hooks/usePermission';
export const FarmerProfileAboutCard = () => {
    var _a, _b, _c, _d, _e;
    const { classes } = useFarmerProfileAboutCardStyles();
    const { t } = useTranslation();
    const { userId, invitationId } = useGetFarmerProfileParamsFromUrl();
    const id = userId || invitationId;
    const { profile, isLoading } = useGetFarmerProfileFromUrlId();
    const assignedContacts = (_a = profile === null || profile === void 0 ? void 0 : profile.contacts) !== null && _a !== void 0 ? _a : [];
    const farmId = ((_c = (_b = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _b === void 0 ? void 0 : _b.farm) === null || _c === void 0 ? void 0 : _c.id) ? Number((_e = (_d = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _d === void 0 ? void 0 : _d.farm) === null || _e === void 0 ? void 0 : _e.id) : undefined;
    const { assignableContacts } = useAssignedContactsFormData({ farmerId: id });
    const { open: showEditForm, handleOpen: handleShowEditForm, handleClose: handleHideEditForm } = useToggle();
    const { canAssignAssociateToFarmer } = usePermission();
    const displayAssignContactButton = canAssignAssociateToFarmer ? !!id && !!farmId : !!id;
    if (isLoading)
        return _jsx(Skeleton, { variant: "rounded", animation: "wave", height: 250 });
    if (!profile)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: classes.card, children: [_jsx(Typography, { className: classes.title, variant: "body1", children: t('farmers.profile.about.title') }), _jsxs("div", { className: classes.contactTitle, children: [_jsx(Typography, { variant: "body1", color: "secondary", children: t('farmers.profile.about.subtitles.contact') }), displayAssignContactButton && (_jsx(AssignContactButton, { farmerId: id, onClick: handleShowEditForm }))] }), showEditForm ? (_jsx(ContactForm, { profileInvitationId: invitationId, farmId: farmId, assignedContacts: assignedContacts, assignableContacts: assignableContacts, onSave: handleHideEditForm, onCancel: handleHideEditForm })) : (_jsx(ContactList, { contacts: assignedContacts }))] }));
};

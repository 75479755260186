export const computeObjectAsQueryString = (object) => {
    const computedQueryString = Object.entries(object)
        .filter(([, values]) => (Array.isArray(values) ? (values === null || values === void 0 ? void 0 : values.length) > 0 : values !== null))
        .map(([key, values]) => Array.isArray(values)
        ? values.map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        : `${encodeURIComponent(key)}=${encodeURIComponent(values)}`)
        .flat()
        .join('&');
    return computedQueryString ? `?${computedQueryString}` : '';
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createTheme, ThemeProvider, Tooltip, Typography, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import useValidationFieldStyle from './ValidationField.style';
import { deepmerge } from '@mui/utils';
export const ValidationField = ({ children, rules, open }) => {
    const { classes } = useValidationFieldStyle();
    const theme = useTheme();
    const newTheme = createTheme(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deepmerge(theme, {
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        padding: '10px',
                        borderRadius: '10px',
                        top: '5px',
                        backgroundColor: '#fff',
                        color: '#b07c48',
                        boxShadow: '0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 15%)',
                    },
                    arrow: {
                        color: '#fff',
                    },
                },
            },
        },
    }));
    return (_jsx(ThemeProvider, { theme: newTheme, children: _jsx(Tooltip, { open: open, placement: "right", title: _jsx(_Fragment, { children: rules.map((rule, i) => (_jsxs("div", { className: classes.ruleRow, children: [rule.isValid ? (_jsx(CheckCircleIcon, { className: classes.successColor, fontSize: "inherit" })) : (_jsx(ErrorIcon, { color: "action", fontSize: "inherit" })), _jsx(Typography, { color: "secondary", variant: "body2", children: rule.text })] }, `${rule.text}-${i}`))) }), arrow: true, children: _jsx("div", { children: children }) }) }));
};

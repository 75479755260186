import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from '@components/Table/Table';
import { TableLoader } from '@components/Table/TableLoader';
import { useAppDispatch, useAppSelector } from '@hooks';
import { usePermission } from '@hooks/usePermission';
import { Box } from '@mui/material';
import { ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import { PAGE_SIZE, useFarmersTableData } from './useFarmersTableData';
import { useTranslation } from 'react-i18next';
import { USER_STATUS } from '@/types/user.types';
import { changePage, changeSorting, FARMERS_SORTABLE_FIELDS, selectFarmersListPage, selectFarmersListSortingOptions, } from '@features/farmer/listFarmers/farmersList.slice';
import { useCallback } from 'react';
import { useFarmersTableStyles } from '@features/farmer/listFarmers/Table/FarmersTable.style';
import { useFarmersListFilters } from '../useFarmersListFilters';
import { useFarmersListFilterDefaultValues } from '../useFarmersListFilterDefaultValues';
const mapDataGridColumnToSortField = (sortModel) => {
    switch (sortModel === null || sortModel === void 0 ? void 0 : sortModel.field) {
        case 'cohort':
            return FARMERS_SORTABLE_FIELDS.COHORT;
        case 'farm_season_status':
            return FARMERS_SORTABLE_FIELDS.FARM_SEASON_STATUS;
        default:
            return FARMERS_SORTABLE_FIELDS.FULL_NAME;
    }
};
export const FarmersTable = () => {
    const { columns, rows, getRowId, isLoading, isFetching, count } = useFarmersTableData();
    const navigate = useNavigate();
    const { canSeeAssociateFarmers, canSeeAssociateAssignedFarmers } = usePermission();
    const dispatch = useAppDispatch();
    const sorting = useAppSelector(selectFarmersListSortingOptions);
    const page = useAppSelector(selectFarmersListPage);
    const { defaultHiddenColumns } = useFarmersListFilterDefaultValues();
    const { t } = useTranslation();
    const { classes } = useFarmersTableStyles({ sorting });
    const { handleHiddenColumnsFilterChange } = useFarmersListFilters();
    const onPageChange = (page) => dispatch(changePage({ page }));
    const handleSortModelChange = useCallback((sortModel) => {
        var _a;
        dispatch(changeSorting({
            sortModel: {
                field: mapDataGridColumnToSortField(sortModel[0]),
                order: ((_a = sortModel[0]) === null || _a === void 0 ? void 0 : _a.sort) || 'asc',
            },
        }));
    }, [dispatch]);
    const handleGoToProfile = ({ row }) => {
        if (row.user_status === USER_STATUS.INVITATION_REFUSED)
            return;
        const invitationId = row === null || row === void 0 ? void 0 : row.invitation_id;
        const userId = row === null || row === void 0 ? void 0 : row.user_id;
        const queryString = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && userId
            ? `?userId=${userId}`
            : invitationId
                ? `?invitationId=${invitationId}`
                : '';
        navigate(`${ROUTES.FARMER_PROFILE}${queryString}`);
    };
    const handleColumnVisibilityChange = (shownColumns) => {
        const hiddenColumns = Object.keys(shownColumns).filter((columnName) => shownColumns[columnName] === false);
        handleHiddenColumnsFilterChange(hiddenColumns);
    };
    const hiddenColumns = Object.fromEntries(defaultHiddenColumns.map((columnName) => [columnName, false]));
    return (_jsx(Box, { style: { height: '100%' }, children: _jsx(Table, { className: classes.farmersTable, page: page, rowsPerPageOptions: [PAGE_SIZE], pageSize: PAGE_SIZE, onRowClick: handleGoToProfile, rowCount: count, columnVisibilityModel: hiddenColumns, onColumnVisibilityModelChange: handleColumnVisibilityChange, localeText: {
                toolbarColumns: t('teams.grid.toolbar.columns'),
                columnsPanelHideAllButton: t('teams.grid.toolbar.hide-all'),
                columnsPanelShowAllButton: t('teams.grid.toolbar.show-all'),
                columnsPanelTextFieldLabel: t('teams.grid.toolbar.find-column'),
                columnsPanelTextFieldPlaceholder: t('teams.grid.toolbar.column-title'),
                MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) => count === 0 ? '' : `${from} - ${to} ${t('common.of')} ${count}`,
                },
            }, columns: columns, rows: isLoading || isFetching ? [] : rows, autoHeight: false, getRowId: getRowId, loading: isLoading || isFetching, pagination: true, paginationMode: "server", onPageChange: onPageChange, hideFooter: false, components: { LoadingOverlay: TableLoader, Toolbar: Table.Toolbar }, getRowClassName: (params) => `row-${params.row.user_status}`, isRowSelectable: () => false, disableVirtualization: true, sortingMode: "server", onSortModelChange: handleSortModelChange, initialState: {
                sorting: {
                    sortModel: [{ field: 'full_name', sort: 'asc' }],
                },
            } }) }));
};

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useAnalysisProfileCardStyles } from '@/pages/AlarmingDataPage/AnalysisProfileCard/AnalysisProfileCard.style';
import { Avatar } from '@components/Avatar';
import { IconRefresh } from '@components/Icons/IconRefresh';
import { Button, CircularProgress, Typography } from '@mui/material';
import { ROUTES } from '@router/routes';
import { getAvatarInitials } from '@utils/string';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export const AnalysisProfileCard = ({ className, firstName, lastName, userId, onRefresh, isRefetching, minutesAgo, }) => {
    const { t } = useTranslation();
    const { classes, cx } = useAnalysisProfileCardStyles();
    return (_jsxs("div", { className: cx(classes.card, className), children: [_jsxs(Typography, { variant: "h6", children: [t('titles.data-analysis'), " ", t('common.of')] }), _jsxs(Link, { to: `${ROUTES.FARMER_PROFILE}?userId=${userId}`, className: classes.userInfo, children: [_jsx(Avatar, { children: getAvatarInitials(firstName, lastName) }), _jsxs(Typography, { variant: "body1", children: [firstName, " ", lastName] })] }), !!onRefresh && (_jsxs(Button, { className: classes.refreshButton, variant: "text", onClick: onRefresh, children: [_jsxs(Typography, { variant: "body2", children: [t('common.last-updated'), " : ", minutesAgo, " ", t('common.minutes-ago', { count: minutesAgo })] }), isRefetching ? _jsx(CircularProgress, { size: 14 }) : _jsx(IconRefresh, { className: classes.refreshIcon })] }))] }));
};

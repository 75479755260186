var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryParams } from '@hooks';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useAcceptInviteMutation } from '@services';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAcceptInviteCardStyle from './AcceptInviteCard.style';
function AcceptInviteCard() {
    var _a, _b;
    const { classes } = useAcceptInviteCardStyle();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUserExisting, setIsUserExisting] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [acceptInvite] = useAcceptInviteMutation();
    const { queryParams } = useQueryParams();
    const inviteToken = (_a = queryParams.get('token')) !== null && _a !== void 0 ? _a : '';
    const email = (_b = queryParams.get('email')) !== null && _b !== void 0 ? _b : '';
    const redirect = (toLogin) => {
        if (toLogin) {
            navigate(`/login`);
        }
        else {
            navigate(`/register-invite/${id}?token=${inviteToken}&email=${encodeURIComponent(email)}`);
        }
    };
    const fetchDataAndredirect = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield acceptInvite({
                id: parseInt(id),
                token: String(inviteToken),
            }).unwrap();
            setIsUserExisting(!!(response === null || response === void 0 ? void 0 : response.user_id));
            setLoading(false);
        }
        catch (error) {
            console.error(error);
            setIsError(true);
            setErrorMessageOnError(error);
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchDataAndredirect();
    }, []);
    const onClick = () => {
        redirect(isError || isUserExisting);
    };
    const setErrorMessageOnError = (error) => {
        var _a, _b, _c, _d;
        if ((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.status) {
            switch ((_b = error === null || error === void 0 ? void 0 : error.data) === null || _b === void 0 ? void 0 : _b.status) {
                case 400:
                    switch ((_c = error === null || error === void 0 ? void 0 : error.data) === null || _c === void 0 ? void 0 : _c.code) {
                        case 'INVITATION_TOKEN_USED':
                            setErrorMessage({
                                title: t('titles.accept-invite-error.invitation-used'),
                                subtitle: t('subtitles.accept-invite-error.invitation-used'),
                            });
                            break;
                        case 'INVALID_INVITATION_TOKEN':
                            setErrorMessage({
                                title: t('titles.accept-invite-error.invitation-invalid'),
                                subtitle: t('subtitles.accept-invite-error.invitation-invalid'),
                            });
                            break;
                        default:
                            setErrorMessage({
                                title: t('titles.accept-invite-error.server-error'),
                                subtitle: t('subtitles.accept-invite-error.server-error'),
                            });
                            break;
                    }
                    break;
                case 404:
                    setErrorMessage({
                        title: t('titles.accept-invite-error.invitation-invalid'),
                        subtitle: t('subtitles.accept-invite-error.invitation-invalid'),
                    });
                    break;
                case 500:
                    setErrorMessage({
                        title: t('titles.accept-invite-error.server-error'),
                        subtitle: t('subtitles.accept-invite-error.server-error'),
                    });
                    break;
                default:
                    setErrorMessage({
                        title: t('titles.accept-invite-error.server-error'),
                        subtitle: t('subtitles.accept-invite-error.server-error'),
                    });
                    break;
            }
        }
        if (!((_d = error === null || error === void 0 ? void 0 : error.data) === null || _d === void 0 ? void 0 : _d.status) && (error === null || error === void 0 ? void 0 : error.code)) {
            setErrorMessage({
                title: t('titles.accept-invite-error.server-error'),
                subtitle: t('subtitles.accept-invite-error.server-error'),
            });
        }
    };
    return (_jsxs(Box, { children: [loading ? (_jsx(CircularProgress, { className: classes.loading, size: "64px" })) : errorMessage ? (_jsx(CancelIcon, { className: classes.loading, color: "error" })) : (_jsx(CheckCircleIcon, { className: classes.loading, color: "success" })), _jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.contentGroup, children: [_jsx("div", { className: classes.titleContainer, children: _jsx(Typography, { align: "center", variant: "h4", style: { fontSize: '24px' }, children: loading
                                    ? t('titles.accept-invite-loading')
                                    : errorMessage
                                        ? t(errorMessage.title)
                                        : t('titles.accept-invite-success') }) }), _jsx(Typography, { align: "center", variant: "h4", style: { fontSize: '16px' }, children: loading
                                ? t('subtitles.accept-invite-loading')
                                : errorMessage
                                    ? t(errorMessage.subtitle)
                                    : t('subtitles.accept-invite-success') }), !loading && (_jsx(Box, { mt: "40px", children: _jsx(Button, { fullWidth: true, onClick: onClick, children: t('buttons.continue') }) }))] }) })] }));
}
export default AcceptInviteCard;

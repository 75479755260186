var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAppSelector, useAuth } from '@hooks';
import { selectFarmersListCohorts, selectFarmersListContacts, selectFarmersListFarmSeasonStatuses, selectFarmersListSearch, selectFarmersListSeasons, selectFarmersListSortingOptions, selectFarmersListUserStatuses, } from '@features/farmer/listFarmers/farmersList.slice';
import { computeObjectAsQueryString } from '@utils/computeObjectAsQueryString';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
export const useFarmersExportDownload = () => {
    var _a;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const seasons = useAppSelector(selectFarmersListSeasons);
    const cohorts = useAppSelector(selectFarmersListCohorts);
    const contacts = useAppSelector(selectFarmersListContacts);
    const search = useAppSelector(selectFarmersListSearch);
    const userStatuses = useAppSelector(selectFarmersListUserStatuses);
    const farmSeasonStatuses = useAppSelector(selectFarmersListFarmSeasonStatuses);
    const sortingOptions = useAppSelector(selectFarmersListSortingOptions);
    const { user } = useAuth();
    const queryParams = {
        seasonId: (_a = seasons === null || seasons === void 0 ? void 0 : seasons.ids) !== null && _a !== void 0 ? _a : null,
        cohortId: cohorts === null || cohorts === void 0 ? void 0 : cohorts.map((cohort) => cohort.id),
        contactId: contacts === null || contacts === void 0 ? void 0 : contacts.map((contact) => contact.id),
        search: search,
        userStatus: userStatuses,
        farmSeasonStatus: farmSeasonStatuses,
        sortField: sortingOptions.field,
        sortOrder: sortingOptions.order,
        lang: (user === null || user === void 0 ? void 0 : user.language.iso_code) || 'en',
    };
    const queryString = computeObjectAsQueryString(queryParams);
    const handleDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setError(null);
            const response = yield fetch(`${process.env.API_URL}/private/farmers/list/export${queryString}`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                setError(response.statusText);
                enqueueSnackbar(t('errors.export-xlsx-error'), { variant: 'error' });
            }
            const blob = yield response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const date = new Date();
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const [month, day, year] = date.toLocaleDateString('en-US', options).split('/');
            const formattedDate = `${year}_${month}_${day}`;
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `Farmers_List_SoilCapital_${formattedDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (_e) {
            setError(t('errors.export-xlsx-error'));
            enqueueSnackbar(t('errors.export-xlsx-error'), { variant: 'error' });
        }
        finally {
            setIsLoading(false);
        }
    });
    return { handleDownload, isLoading, error };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext as createReactContext, useContext as useReactContext, useMemo, } from 'react';
const createContext = (rootComponentName, defaultContext) => {
    const Context = createReactContext(defaultContext);
    const Provider = (props) => {
        const { children } = props, context = __rest(props, ["children"]);
        const value = useMemo(() => context, Object.values(context));
        return _jsx(Context.Provider, { value: value, children: children });
    };
    Provider.displayName = rootComponentName + 'Provider';
    const useContext = (consumerName) => {
        const context = useReactContext(Context);
        if (context)
            return context;
        if (defaultContext !== undefined)
            return defaultContext;
        // if a defaultContext wasn't specified, it's a required context.
        throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
    };
    return [Provider, useContext];
};
/** Extends createContext to support scoped contexts.
 * Allows multiple independent instances of the same context.
 * Useful for composing multiple contexts without conflicts
 */
const createContextScope = (scopeName, createContextScopeDeps = []) => {
    let defaultContexts = [];
    const createContext = (rootComponentName, defaultContext) => {
        const BaseContext = createReactContext(defaultContext);
        const index = defaultContexts.length;
        defaultContexts = [...defaultContexts, defaultContext];
        const Provider = (props) => {
            var _a;
            const { scope, children } = props, context = __rest(props, ["scope", "children"]);
            const Context = ((_a = scope === null || scope === void 0 ? void 0 : scope[scopeName]) === null || _a === void 0 ? void 0 : _a[index]) || BaseContext;
            const value = useMemo(() => context, Object.values(context));
            return _jsx(Context.Provider, { value: value, children: children });
        };
        Provider.displayName = rootComponentName + 'Provider';
        const useContext = (consumerName, scope) => {
            var _a;
            const Context = ((_a = scope === null || scope === void 0 ? void 0 : scope[scopeName]) === null || _a === void 0 ? void 0 : _a[index]) || BaseContext;
            const context = useReactContext(Context);
            if (context)
                return context;
            if (defaultContext !== undefined)
                return defaultContext;
            // if a defaultContext wasn't specified, it's a required context.
            throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
        };
        return [Provider, useContext];
    };
    const createScope = () => {
        const scopeContexts = defaultContexts.map((defaultContext) => createReactContext(defaultContext));
        return (scope) => {
            const contexts = (scope === null || scope === void 0 ? void 0 : scope[scopeName]) || scopeContexts;
            return useMemo(() => ({ [`__scope${scopeName}`]: Object.assign(Object.assign({}, scope), { [scopeName]: contexts }) }), [scope, contexts]);
        };
    };
    createScope.scopeName = scopeName;
    return [createContext, composeContextScopes(createScope, ...createContextScopeDeps)];
};
const composeContextScopes = (...scopes) => {
    const baseScope = scopes[0];
    if (scopes.length === 1)
        return baseScope;
    const createScope = () => {
        const scopeHooks = scopes.map((createScope) => ({
            useScope: createScope(),
            scopeName: createScope.scopeName,
        }));
        return (overrideScopes) => {
            const nextScopes = scopeHooks.reduce((nextScopes, { useScope, scopeName }) => {
                // We are calling a hook inside a callback which React warns against to avoid inconsistent
                // renders, however, scoping doesn't have render side effects so we ignore the rule.
                const scopeProps = useScope(overrideScopes);
                const currentScope = scopeProps[`__scope${scopeName}`];
                return Object.assign(Object.assign({}, nextScopes), currentScope);
            }, {});
            return useMemo(() => ({ [`__scope${baseScope.scopeName}`]: nextScopes }), [nextScopes]);
        };
    };
    createScope.scopeName = baseScope.scopeName;
    return createScope;
};
export { createContext, createContextScope };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
export const useDataRefetch = ({ onInitialFetch, refetch, onRefetch, isLoading, isError, hasData, automaticRefreshDelay, errorMessage, successMessage, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const initialFetch = useRef(false);
    const handleRefetch = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield refetch();
            onRefetch === null || onRefetch === void 0 ? void 0 : onRefetch();
            if (successMessage)
                enqueueSnackbar(successMessage, { variant: 'success' });
        }
        catch (_e) {
            if (errorMessage)
                enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    });
    // Run callback on initial fetch
    useEffect(() => {
        initialFetch.current = !isLoading && !isError && hasData && !initialFetch.current;
        if (initialFetch.current)
            onInitialFetch === null || onInitialFetch === void 0 ? void 0 : onInitialFetch();
    }, [isLoading, isError, hasData]);
    // Automatic refetch every X
    useEffect(() => {
        let intervalId;
        if (automaticRefreshDelay) {
            intervalId = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
                yield handleRefetch();
            }), automaticRefreshDelay);
        }
        return () => {
            if (intervalId)
                clearInterval(intervalId);
        };
    }, [automaticRefreshDelay]);
    return { handleRefetch };
};

const selectCss = {
    root: ({ theme }) => ({
        '& > .MuiSelect-select ': {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            border: 'none',
            height: 40,
            minHeight: 40,
        },
        fieldset: {
            border: 'none',
            minHeight: 40,
        },
        svg: {
            fill: theme.palette.primary.main,
        },
    }),
    select: () => ({
        height: 40,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
    }),
};
const MuiSelect = { styleOverrides: selectCss };
export default MuiSelect;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { IconDownload } from '@components/Icons';
import { useTranslation } from 'react-i18next';
import { useFarmersExportDownload } from '@features/farmer/export/useFarmersExportDownload';
export const FarmersExportButton = () => {
    const { t } = useTranslation();
    const { handleDownload, isLoading } = useFarmersExportDownload();
    return (_jsxs(Button, { variant: "outlined", disabled: isLoading, onClick: handleDownload, children: [_jsx(IconDownload, {}), t('common.export-xlsx')] }));
};

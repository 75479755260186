import { jsx as _jsx } from "react/jsx-runtime";
import { useMembersTableStyles } from '@features/member/MembersTable/MembersTable.style';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMembers } from '@hooks/useGetMembers';
import { usePermission } from '@hooks/usePermission';
export const useMembersTableData = () => {
    const { t } = useTranslation();
    const { classes, cx } = useMembersTableStyles();
    const { data: rows, isLoading } = useGetMembers();
    const { canAccessPlatformAsAssociate } = usePermission();
    const isMemberPendingCheck = (row) => !canAccessPlatformAsAssociate && (!row.user_id || !row.partner_user_id);
    const getRowId = (row) => { var _a, _b; return (_b = (_a = row.partner_user_id) !== null && _a !== void 0 ? _a : row.user_id) !== null && _b !== void 0 ? _b : row.invitation_id; };
    const renderPendingInviteCell = (params) => {
        const isMemberPending = isMemberPendingCheck(params.row);
        return _jsx("span", { className: cx(isMemberPending && classes.pending), children: params.value });
    };
    const sharedColumnProps = useMemo(() => ({
        sortable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
    }), []);
    const columns = useMemo(() => [
        Object.assign(Object.assign({ field: 'full_name', headerName: t('teams.grid.columns.full-name'), minWidth: 250 }, sharedColumnProps), { valueGetter: ({ row }) => row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` : '-', renderCell: renderPendingInviteCell }),
        Object.assign(Object.assign({ field: 'email', headerName: t('teams.grid.columns.email'), width: 350 }, sharedColumnProps), { valueGetter: ({ row }) => row.email, renderCell: (params) => {
                const isMemberPending = isMemberPendingCheck(params.row);
                return (_jsx(Tooltip, { title: params.value, placement: "right", arrow: true, children: _jsx("p", { className: cx(classes.email, isMemberPending && classes.pending), children: params.value }) }));
            } }),
        Object.assign(Object.assign({ field: 'role', headerName: t('teams.grid.columns.role'), width: 250 }, sharedColumnProps), { valueGetter: ({ row }) => t(row.role.translation_slug), renderCell: renderPendingInviteCell }),
    ], [t, sharedColumnProps]);
    return { rows, columns, isLoading, getRowId };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import i18n from '@/i18n';
import { ALARMING_DATA_SCOPE, canConfirmAlarmingData, isAlarmingDataConfirmationDisabled, } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { AlarmingDataCheckAccordion } from '@features/alarmingData/components/AlarmingDataCheck/AlarmingDataCheckAccordion';
import { AlarmingDataCheckCard } from '@features/alarmingData/components/AlarmingDataCheck/AlarmingDataCheckCard';
import { AlarmingDataGroup } from '@features/alarmingData/components/AlarmingDataGroup/AlarmingDataGroup';
import { AlarmingDataSelection } from '@features/alarmingData/components/AlarmingDataSelection/AlarmingDataSelection';
import { ALARMING_DATA_CHECK_SLUGS } from '@features/alarmingData/constants';
import { useTranslation } from 'react-i18next';
const getGroupsTitles = (scope, groups) => {
    if (scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD)
        return groups.map((group) => {
            var _a;
            return (_a = group.alarmingData) === null || _a === void 0 ? void 0 : _a.filter((d) => { var _a; return (_a = d.farmSeasonField) === null || _a === void 0 ? void 0 : _a.name; }).map((d) => { var _a; return (_a = d === null || d === void 0 ? void 0 : d.farmSeasonField) === null || _a === void 0 ? void 0 : _a.name; }).join(', ');
        });
    if (scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD_CROP)
        return groups.map((group) => {
            var _a;
            return (_a = group.alarmingData) === null || _a === void 0 ? void 0 : _a.filter((d) => { var _a; return (_a = d.farmSeasonFieldCrop) === null || _a === void 0 ? void 0 : _a.name; }).map((d) => { var _a; return (_a = d === null || d === void 0 ? void 0 : d.farmSeasonFieldCrop) === null || _a === void 0 ? void 0 : _a.name; }).join(', ');
        });
    return [];
};
const getCardTitle = (scope, alarmingData) => {
    var _a, _b;
    if (scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD)
        return (_a = alarmingData.farmSeasonField) === null || _a === void 0 ? void 0 : _a.name;
    if (scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD_CROP)
        return (_b = alarmingData.farmSeasonFieldCrop) === null || _b === void 0 ? void 0 : _b.name;
    return ALARMING_DATA_CHECK_SLUGS[alarmingData.check.name]
        ? i18n.t(ALARMING_DATA_CHECK_SLUGS[alarmingData.check.name])
        : alarmingData.check.name;
};
export const AlarmingDataCard = ({ className, check, onConfirm, isLoading = false, scope }) => {
    const { t } = useTranslation();
    const { name, groups, ungrouped } = check;
    const groupTitles = getGroupsTitles(scope, groups);
    return (_jsxs(AlarmingDataCheckAccordion, { className: className, title: (ALARMING_DATA_CHECK_SLUGS === null || ALARMING_DATA_CHECK_SLUGS === void 0 ? void 0 : ALARMING_DATA_CHECK_SLUGS[name]) ? t(ALARMING_DATA_CHECK_SLUGS[name]) : name, count: check.count, children: [groups.map((group, index) => {
                if (group.alarmingData.length === 1) {
                    const alarmingData = group.alarmingData[0];
                    return (_jsx(AlarmingDataCheckCard, { id: alarmingData.id, title: getCardTitle(scope, alarmingData), values: alarmingData.values, showConfirm: canConfirmAlarmingData(alarmingData), isConfirmDisabled: isAlarmingDataConfirmationDisabled(alarmingData), onConfirm: onConfirm, comments: group.comments }, alarmingData.id));
                }
                return (_jsx(AlarmingDataGroup, { className: className, title: group.isResolved ? undefined : group.name || groupTitles[index], showConfirm: !group.isResolved, onConfirm: onConfirm, isConfirmDisabled: isAlarmingDataConfirmationDisabled(group.alarmingData[0]), group: group, scope: scope, isLoading: isLoading }, group.id));
            }), ungrouped.length > 0 && _jsx(AlarmingDataSelection, { scope: scope, data: ungrouped, onConfirm: onConfirm })] }));
};

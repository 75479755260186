var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import SvgIcon from '@mui/material/SvgIcon';
export const IconFile = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24", fill: "none", color: color }, rest, { children: _jsx("path", { d: "M14.5 2.26953V6.40007C14.5 6.96012 14.5 7.24015 14.609 7.45406C14.7049 7.64222 14.8578 7.7952 15.046 7.89108C15.2599 8.00007 15.5399 8.00007 16.1 8.00007H20.2305M14.5 17H8.5M16.5 13H8.5M20.5 9.98822V17.2C20.5 18.8802 20.5 19.7202 20.173 20.362C19.8854 20.9265 19.4265 21.3854 18.862 21.673C18.2202 22 17.3802 22 15.7 22H9.3C7.61984 22 6.77976 22 6.13803 21.673C5.57354 21.3854 5.1146 20.9265 4.82698 20.362C4.5 19.7202 4.5 18.8802 4.5 17.2V6.8C4.5 5.11984 4.5 4.27976 4.82698 3.63803C5.1146 3.07354 5.57354 2.6146 6.13803 2.32698C6.77976 2 7.61984 2 9.3 2H12.5118C13.2455 2 13.6124 2 13.9577 2.08289C14.2638 2.15638 14.5564 2.27759 14.8249 2.44208C15.1276 2.6276 15.387 2.88703 15.9059 3.40589L19.0941 6.59411C19.613 7.11297 19.8724 7.3724 20.0579 7.67515C20.2224 7.94356 20.3436 8.2362 20.4171 8.5423C20.5 8.88757 20.5 9.25445 20.5 9.98822Z", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};

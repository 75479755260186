import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SeasonFilter } from '@features/farmer/listFarmers/Filters/SeasonFilter/SeasonFilter';
import { Button, Typography } from '@mui/material';
import { useFarmersTableHeaderStyles } from '@features/farmer/listFarmers/Header/FarmersTableHeader.style';
import { SearchInput } from '@components/Form/SearchInput/SearchInput';
import { useAppSelector } from '@hooks/redux';
import { selectFarmersListSearch } from '../farmersList.slice';
import { useTranslation } from 'react-i18next';
import { useGetFarmersList } from '../useGetFarmersList';
import { CohortFilter } from '@features/farmer/listFarmers/Filters/CohortFilter/CohortFilter';
import { useFarmersListFilters } from '@features/farmer/listFarmers/useFarmersListFilters';
import { ContactFilter } from '@features/farmer/listFarmers/Filters/ContactFilter/ContactFilter';
import { UserStatusFilter } from '../Filters/UserStatusFilter/UserStatusFilter';
import { ClearAllFilter } from '@features/farmer/listFarmers/Filters/ClearAllFilter/ClearAllFilter';
import { FarmSeasonStatusFilter } from '@features/farmer/listFarmers/Filters/FarmSeasonStatusFilter/FarmSeasonStatusFilter';
import { FarmersExportButton } from '@features/farmer/export/FarmersExportButton';
import { usePermission } from '@hooks/usePermission';
import { useShowInvitationModal } from '@features/invite/hooks/useShowInvitationModal';
import { InviteFarmersModal } from '@features/invite/farmer/Modal/InviteFarmersModal';
import { PartnerFilter } from '@features/farmer/listFarmers/Filters/PartnerFilter/PartnerFilter';
export const FarmersTableHeader = () => {
    const { classes } = useFarmersTableHeaderStyles();
    const { t } = useTranslation();
    const { count } = useGetFarmersList();
    const search = useAppSelector(selectFarmersListSearch);
    const { defaultSeason, defaultCohorts, defaultPartners, defaultContacts, defaultUserStatuses, defaultFarmSeasonStatuses, handleSeasonChange, handleCohortChange, handleSearchChange, handleSearchReset, handlePartnersChange, handleContactsChange, handleUserStatusChange, handleFarmSeasonStatusChange, handleClearAll, } = useFarmersListFilters();
    const { canInviteFarmers } = usePermission();
    const { open, shouldResend, openInviteModal, openResendModal, onClose } = useShowInvitationModal();
    return (_jsxs("div", { children: [_jsxs("div", { className: classes.titleContainer, children: [_jsx(Typography, { variant: "h2", children: t('common.my-farmers') }), !!defaultSeason && _jsx(SeasonFilter, { value: defaultSeason, onChange: handleSeasonChange }), _jsxs("div", { className: classes.actionsContainer, children: [canInviteFarmers && (_jsxs(_Fragment, { children: [_jsx(Button, { size: "small", color: "info", onClick: openResendModal, children: t('farmers.invite.resend-invite') }), _jsx(Button, { size: "small", onClick: openInviteModal, children: t('farmers.invite-farmers') })] })), _jsx(FarmersExportButton, {})] })] }), _jsxs("div", { className: classes.filtersContainer, children: [_jsx(PartnerFilter, { values: defaultPartners, onChange: handlePartnersChange }), _jsx(ContactFilter, { values: defaultContacts, onChange: handleContactsChange }), _jsx(CohortFilter, { values: defaultCohorts, onChange: handleCohortChange }), _jsx(UserStatusFilter, { values: defaultUserStatuses, onChange: handleUserStatusChange }), _jsx(FarmSeasonStatusFilter, { values: defaultFarmSeasonStatuses, onChange: handleFarmSeasonStatusChange }), defaultCohorts.length ||
                        defaultContacts.length ||
                        defaultUserStatuses.length ||
                        defaultFarmSeasonStatuses.length ? (_jsx(ClearAllFilter, { onClear: handleClearAll })) : undefined] }), _jsxs("div", { className: classes.searchContainer, children: [_jsx(SearchInput, { defaultValue: search, onChange: handleSearchChange, onReset: handleSearchReset, placeholder: t('placeholders.search-name-email') }), _jsx(Typography, { variant: "body2", children: t('common.farmer', { count }) })] }), canInviteFarmers && _jsx(InviteFarmersModal, { open: open, onClose: onClose, shouldResend: shouldResend })] }));
};

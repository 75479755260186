import { alpha } from '@mui/material/styles';
export const palette = {
    common: { black: '#000', white: '#fff' },
    primary: {
        light: alpha('#426771', 0.75),
        main: '#426771',
        dark: '#2E484F',
        contrastText: '#fff',
        50: '#F9FBFE',
        100: '#F3F7FF',
        200: '#E7EDF9',
        300: '#C6D1D4',
        400: '#426771',
        500: '#305B66',
        600: '#1D4F5B',
        700: '#21405E',
        800: '#12375B',
        900: '#052848',
    },
    secondary: {
        light: alpha('#B07D4A', 0.75),
        main: '#B07D4A',
        dark: '#7B5733',
        contrastText: '#fff',
        50: '#FEFCF5',
        100: '#F6F3E5',
        200: '#E9E4CD',
        300: '#BEBCAA',
        400: '#8E8B76',
        500: '#F7D382',
        600: '#B07D4A',
        700: '#7B5733',
        800: '#4D4F45',
        900: '#3C3E38',
    },
    error: {
        light: alpha('#F44336', 0.1),
        main: '#F44336',
        dark: '#F44336',
        contrastText: '#fff',
        50: '#FFEBEE',
        100: '#FFCDD2',
        200: '#EF9A9A',
        300: '#E57373',
        400: '#EF5350',
        500: '#F44336',
        600: '#F00',
        700: '#D32F2F',
        800: '#C62828',
        900: '#B71C1C',
    },
    warning: {
        light: alpha('#EC9235', 0.1),
        main: '#EC9235',
        dark: '#EC9235',
        contrastText: '#fff',
        50: '#FDF3E2',
        100: '#FAE1B7',
        200: '#FFCC80',
        300: '#FFB74D',
        400: '#FFA726',
        500: '#FF9800',
        600: '#FB8C00',
        700: '#F57C00',
        800: '#EF6C00',
        900: '#E65100',
    },
    info: {
        light: alpha('#E7EDF9', 0.75),
        main: '#E7EDF9',
        dark: '#BAC4DB',
        contrastText: '#426771',
        50: '#E5F4FD',
        100: '#BDE4F9',
        200: '#94D2F6',
        300: '#70C0F2',
        400: '#5AB3F0',
        500: '#4BA6EE',
        600: '#4599DF',
        700: '#3B86CB',
        800: '#3375B8',
        900: '#235696',
    },
    success: {
        light: alpha('#57BA65', 0.1),
        main: '#57BA65',
        dark: '#3CAC74',
        contrastText: '#fff',
        50: '#E9F5E9',
        100: '#C4E6C7',
        200: '#9ED5A4',
        300: '#76C67F',
        400: '#57BA65',
        500: '#36AE49',
        600: '#2D9F40',
        700: '#218D35',
        800: '#147C2A',
        900: '#005D18',
    },
    grey: {
        50: '#fafafa',
        100: '#D1D8DB',
        200: '#eeeeee',
        300: '#d2d2d2',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
    },
    neutral: {
        50: '#FAFAFA',
        100: '#F8F8F8',
        200: '#EEE',
        300: '#BDBDBD',
        400: '#BDBDBD',
        500: '#BDBDBD',
        600: '#BDBDBD',
        700: '#BDBDBD',
        800: '#BDBDBD',
        900: '#BDBDBD',
    },
    layout: {
        main: '#f6f3E5',
    },
    content: {
        main: '#fff',
    },
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LandscapeFieldsSrc from '@images/landscape-fields-medium.jpg';
import logoSoilcapitalSrc from '@images/logo-soilcapital.png';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMobileHardstopPageStyle from './MobileHardstopPage.style';
const MobileHardstopPage = () => {
    const { classes } = useMobileHardstopPageStyle();
    const redirectUrl = 'https://soilcapital.com';
    const { t } = useTranslation();
    return (_jsxs("div", { className: classes.screenContainer, children: [_jsxs("div", { className: classes.textContainer, children: [_jsx("img", { alt: 'logo', src: logoSoilcapitalSrc, style: { maxWidth: '140px', height: '38px' } }), _jsx(Typography, { className: classes.title, color: "primary", variant: "h1", children: t('pages.mobile-hardstop.title') }), _jsx(Typography, { className: classes.subTitle, color: "primary", variant: "subtitle1", children: t('pages.mobile-hardstop.sub-title') }), _jsx(Typography, { className: classes.text, color: "primary", variant: "body1", children: t('pages.mobile-hardstop.text') })] }), _jsxs("div", { className: classes.footerContainer, children: [_jsx("img", { alt: "landscape with fields as far as the eye can see", className: classes.footerImg, src: LandscapeFieldsSrc }), _jsx("div", { className: classes.buttonRedirectContainer, children: _jsx(Button, { className: classes.buttonGreen, href: redirectUrl, fullWidth: true, children: t('pages.mobile-hardstop.button-redirect') }) })] })] }));
};
export default MobileHardstopPage;

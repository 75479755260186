import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@components/Select';
import { useSeasonFilterStyles } from '@features/farmer/listFarmers/Filters/SeasonFilter/SeasonFilter.style';
import { capitalize, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetSeasons } from '@hooks/useGetSeasons';
export const SeasonFilter = ({ value, onChange, }) => {
    const { classes } = useSeasonFilterStyles();
    const { t } = useTranslation();
    const { seasonIdsGroupedByYear: options } = useGetSeasons();
    if (!options || options.length === 0)
        return _jsx(_Fragment, {});
    const handleSeasonsChange = (season) => onChange(season);
    const getOptionLabel = (option) => option.year;
    const getOptionValue = (option) => option.ids.join(',');
    return (_jsx(Select, { value: value, options: options, onChange: handleSeasonsChange, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (season) => `${capitalize(t('common.season'))} ${season.year}`, renderOption: (option, props) => (_jsx(Select.Option, Object.assign({ className: classes.seasonBox }, props, { children: _jsx(Typography, { className: classes.season, variant: "body1", children: option.year }) }))), MenuProps: {
            PaperProps: {
                className: classes.container,
            },
        } }));
};

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import i18n from '@/i18n';
import { ALARMING_DATA_VALUES_SLUGS, UNITS_SLUGS } from '@features/alarmingData/constants';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataValuesStyles } from './AlarmingDataValues.style';
const getSluggedAlarmingData = (value) => {
    if (!value)
        return '';
    return value
        .split(' ')
        .map((word) => (ALARMING_DATA_VALUES_SLUGS[word] ? i18n.t(ALARMING_DATA_VALUES_SLUGS[word]) : i18n.t(word)))
        .join(' ');
};
export const AlarmingDataValues = ({ values, className }) => {
    const { t } = useTranslation();
    const { classes, cx } = useAlarmingDataValuesStyles();
    if (!values || (values === null || values === void 0 ? void 0 : values.length) < 0)
        return _jsx(_Fragment, {});
    return (_jsx("div", { className: cx(className, classes.rows), children: values.map((item, index) => {
            if ('groupName' in item) {
                const { groupName: groupName, values: yearValues } = item;
                return (_jsxs("div", { className: classes.row, children: [_jsxs(Typography, { className: classes.year, children: [getSluggedAlarmingData(groupName), ":"] }), _jsx("ul", { className: classes.valuesList, children: yearValues.map((result, resultIndex) => (_jsx("li", { children: _jsxs(Typography, { className: classes.value, children: [getSluggedAlarmingData(result.name), result.name && result.value !== '' ? ': ' : '', getSluggedAlarmingData(result.value), ' ', result.unit ? `${t(UNITS_SLUGS[result.unit])}` : ''] }) }, resultIndex))) })] }, index));
            }
            const { name, value, unit } = item;
            return (_jsx("div", { className: classes.column, children: _jsxs(Typography, { className: classes.value, children: [t(name) ? `${t(name)}` : '', name && value !== '' ? ': ' : '', getSluggedAlarmingData(value), " ", unit ? `${t(UNITS_SLUGS[unit])}` : ''] }) }, index));
        }) }));
};

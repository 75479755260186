import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@components/Table/Table';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useMembersTableData } from './useMembersTableData';
import { TableLoader } from '@components/Table/TableLoader';
import { MemberEditModal } from '@features/member/MemberEditModal/MemberEditModal';
import { usePermission } from '@hooks/usePermission';
import { PAGE_SIZE } from '@features/farmer/listFarmers/Table/useFarmersTableData';
export const MembersTable = () => {
    const { rows, columns, getRowId, isLoading } = useMembersTableData();
    const { canEditPartnerMembers } = usePermission();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState();
    const onClose = () => setModalOpen(false);
    const handleShowMembersModal = ({ row }) => {
        if (canEditPartnerMembers) {
            const selectedMember = rows === null || rows === void 0 ? void 0 : rows.find((r) => r.id === row.id);
            setSelectedMember(selectedMember);
            setModalOpen(true);
        }
    };
    return (_jsxs(Box, { style: { height: '100%' }, children: [_jsx(Table, { rowsPerPageOptions: [PAGE_SIZE], pagination: true, pageSize: PAGE_SIZE, paginationMode: "client", hideFooter: false, onRowClick: handleShowMembersModal, columns: columns, rows: rows || [], autoHeight: false, getRowId: getRowId, loading: isLoading, components: { LoadingOverlay: TableLoader }, disableVirtualization: true }), selectedMember && _jsx(MemberEditModal, { member: selectedMember, open: modalOpen, onClose: onClose })] }));
};

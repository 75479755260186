import { COHORT_FILTER_FARMERS_LIST_STORAGE_KEY, CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY, FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY, PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY, SEASON_FILTER_FARMERS_LIST_STORAGE_KEY, useFarmersListStorageFilters, USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, } from '@features/farmer/listFarmers/useFarmersListStorageFilters';
import { useGetSeasons } from '@hooks/useGetSeasons';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useEffect, useMemo } from 'react';
import { useUserStatuses } from '@features/farmer/listFarmers/Filters/UserStatusFilter/useUserStatuses';
import { useGetCohorts } from '@hooks/useGetCohorts';
import { useGetMembers } from '@hooks/useGetMembers';
import { reconcileStorageWithApiValues } from '@utils/localstorage';
import { changeCohorts, changeContacts, changeFarmSeasonStatuses, changeHiddenColumns, changePartners, changeSeasons, changeUserStatuses, selectFarmersListCohorts, selectFarmersListContacts, selectFarmersListFarmSeasonStatuses, selectFarmersListHiddenColumns, selectFarmersListPartners, selectFarmersListSeasons, selectFarmersListUserStatuses, } from '@features/farmer/listFarmers/farmersList.slice';
import { ROLE_GROUP, useUserRoleGroup } from '@hooks/useUserRoleGroup';
import { USER_STATUS } from '@/types/user.types';
import { useFarmSeasonStatuses } from '@features/farmer/listFarmers/Filters/FarmSeasonStatusFilter/useFarmSeasonStatuses';
import { farmerListColumnNames } from '@/types/farmerListColumn.type';
import { useGetPartners } from '@hooks/useGetPartners';
export const useFarmersListFilterDefaultValues = () => {
    const dispatch = useAppDispatch();
    const defaultSeasons = useAppSelector(selectFarmersListSeasons);
    const defaultCohorts = useAppSelector(selectFarmersListCohorts);
    const defaultPartners = useAppSelector(selectFarmersListPartners);
    const defaultContacts = useAppSelector(selectFarmersListContacts);
    const defaultUserStatuses = useAppSelector(selectFarmersListUserStatuses);
    const defaultFarmSeasonStatuses = useAppSelector(selectFarmersListFarmSeasonStatuses);
    const defaultHiddenColumns = useAppSelector(selectFarmersListHiddenColumns);
    const { storeSeasonFilterToStorage, deleteSeasonFilterFromStorage, storeCohortFilterToStorage, deleteCohortFilterFromStorage, storePartnerFilterToStorage, deletePartnerFilterFromStorage, storeContactFilterToStorage, deleteContactFilterFromStorage, storeUserStatusFilterToStorage, deleteUserStatusFilterFromStorage, storeFarmSeasonStatusFilterToStorage, deleteFarmSeasonStatusFilterFromStorage, storageHiddenColumnsFilterToStorage, deleteHiddenColumnsFilterFromStorage, } = useFarmersListStorageFilters();
    // Values from API
    const { seasonIdsGroupedByYear, isLoading: isLoadingSeasons } = useGetSeasons();
    const { data: cohorts, isLoading: isLoadingCohorts } = useGetCohorts();
    const { data: partners, isLoading: isLoadingPartners } = useGetPartners();
    const { data: members, isLoading: isLoadingContacts } = useGetMembers();
    const partnersWithoutPending = useMemo(() => partners === null || partners === void 0 ? void 0 : partners.filter((partner) => !!(partner === null || partner === void 0 ? void 0 : partner.id)), [partners]);
    const membersWithoutPending = useMemo(() => members === null || members === void 0 ? void 0 : members.filter((member) => !!(member === null || member === void 0 ? void 0 : member.user_id)), [members]);
    const userStatuses = useUserStatuses();
    const { roleGroup } = useUserRoleGroup();
    const farmSeasonStatuses = useFarmSeasonStatuses();
    // Reconcile storage with API values
    useEffect(() => {
        if (!isLoadingSeasons) {
            const reconciledValues = reconcileStorageWithApiValues(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY, seasonIdsGroupedByYear, (season) => season.ids, storeSeasonFilterToStorage, deleteSeasonFilterFromStorage);
            (reconciledValues === null || reconciledValues === void 0 ? void 0 : reconciledValues.length)
                ? dispatch(changeSeasons({ season: reconciledValues === null || reconciledValues === void 0 ? void 0 : reconciledValues[0] }))
                : dispatch(changeSeasons({ season: seasonIdsGroupedByYear === null || seasonIdsGroupedByYear === void 0 ? void 0 : seasonIdsGroupedByYear[0] }));
        }
    }, [dispatch, isLoadingSeasons, seasonIdsGroupedByYear]);
    useEffect(() => {
        if (!isLoadingCohorts) {
            const reconciledValues = reconcileStorageWithApiValues(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY, cohorts !== null && cohorts !== void 0 ? cohorts : [], (cohorts) => cohorts.id, storeCohortFilterToStorage, deleteCohortFilterFromStorage);
            if (reconciledValues.length)
                dispatch(changeCohorts({ cohorts: reconciledValues }));
        }
    }, [dispatch, isLoadingCohorts, cohorts]);
    useEffect(() => {
        if (!isLoadingPartners) {
            const reconciledValues = reconcileStorageWithApiValues(PARTNER_FILTER_FARMERS_LIST_STORAGE_KEY, partnersWithoutPending !== null && partnersWithoutPending !== void 0 ? partnersWithoutPending : [], (partner) => partner.id, storePartnerFilterToStorage, deletePartnerFilterFromStorage);
            if (reconciledValues.length)
                dispatch(changePartners({ partners: reconciledValues }));
        }
    }, [dispatch, isLoadingPartners, partnersWithoutPending]);
    useEffect(() => {
        if (!isLoadingContacts) {
            const reconciledValues = reconcileStorageWithApiValues(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY, membersWithoutPending !== null && membersWithoutPending !== void 0 ? membersWithoutPending : [], (contact) => contact.id, storeContactFilterToStorage, deleteContactFilterFromStorage);
            if (reconciledValues.length)
                dispatch(changeContacts({ contacts: reconciledValues }));
        }
    }, [dispatch, isLoadingContacts, membersWithoutPending]);
    useEffect(() => {
        const reconciledValues = reconcileStorageWithApiValues(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, userStatuses, (status) => status, storeUserStatusFilterToStorage, deleteUserStatusFilterFromStorage);
        if (reconciledValues.length)
            dispatch(changeUserStatuses({ userStatuses: reconciledValues }));
    }, [dispatch]);
    useEffect(() => {
        const reconciledValues = reconcileStorageWithApiValues(FARM_SEASON_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, farmSeasonStatuses, (status) => status, storeFarmSeasonStatusFilterToStorage, deleteFarmSeasonStatusFilterFromStorage);
        if (reconciledValues.length)
            dispatch(changeFarmSeasonStatuses({ farmSeasonStatuses: reconciledValues }));
    }, [dispatch]);
    useEffect(() => {
        const reconciledValues = reconcileStorageWithApiValues(HIDDEN_COLUMNS_FILTER_FARMERS_LIST_STORAGE_KEY, farmerListColumnNames, (status) => status, storageHiddenColumnsFilterToStorage, deleteHiddenColumnsFilterFromStorage);
        if (reconciledValues.length)
            dispatch(changeHiddenColumns({ hiddenColumns: reconciledValues }));
    }, [dispatch]);
    return {
        defaultSeason: defaultSeasons !== null && defaultSeasons !== void 0 ? defaultSeasons : seasonIdsGroupedByYear === null || seasonIdsGroupedByYear === void 0 ? void 0 : seasonIdsGroupedByYear[0],
        defaultCohorts,
        defaultPartners,
        defaultContacts,
        defaultUserStatuses: defaultUserStatuses !== null && defaultUserStatuses !== void 0 ? defaultUserStatuses : (roleGroup === ROLE_GROUP.PARTNER ? [USER_STATUS.SUBSCRIBED] : undefined),
        defaultFarmSeasonStatuses,
        defaultHiddenColumns,
    };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useGetSeasonsQuery } from '@services/season.api';
import { useMemo } from 'react';
export const useGetSeasons = () => {
    const _a = useGetSeasonsQuery(), { data: seasons } = _a, rest = __rest(_a, ["data"]);
    const seasonIdsGroupedByYear = useMemo(() => {
        if (!seasons)
            return [];
        return seasons.reduce((acc, season) => {
            const yearName = season.name;
            const index = acc === null || acc === void 0 ? void 0 : acc.findIndex((season) => season.year === yearName);
            if (index < 0) {
                acc.push({ year: yearName, ids: [season.id] });
                return acc;
            }
            acc[index].ids.push(season.id);
            return acc;
        }, []);
    }, [seasons]);
    return Object.assign({ seasons, seasonIdsGroupedByYear }, rest);
};

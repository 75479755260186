import { makeStyles } from 'tss-react/mui';
export const useAvatarStyle = makeStyles()((theme) => ({
    avatar: {
        border: `2px solid ${theme.palette.common.white}`,
        backgroundColor: '#E7EDF9',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        boxShadow: 'none',
        aspectRatio: 1,
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize,
    },
}));

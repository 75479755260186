export const FARMER_LIST_COLUMNS = {
    FULL_NAME: 'full_name',
    EMAIL: 'email',
    FARM_NAME: 'farm_name',
    PARTNER: 'partner',
    CONTACT: 'contact',
    COHORT: 'cohort',
    USER_STATUS: 'user_status',
    FARM_SEASON_STATUS: 'farm_season_status',
};
export const farmerListColumnNames = Object.values(FARMER_LIST_COLUMNS);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { NewModal } from '@components/NewModal/NewModal';
import { Box, Typography } from '@mui/material';
import { useResultPublication } from '@features/farmer/profile/resultsPublication/useResultPublication';
export const UnpublishResultsModal = ({ open, onClose, farmSeasonId, farmerName, farmName, harvestYear, }) => {
    const { t } = useTranslation();
    const { changeResultPublication, isLoading } = useResultPublication();
    const handleUnpublish = () => changeResultPublication({ farmSeasonId, published: false });
    return (_jsx(NewModal, { open: open, onClose: onClose, onConfirm: handleUnpublish, header: _jsxs(NewModal.Header, { children: [_jsx(NewModal.Title, { children: t('farmers.results.unpublish') }), _jsx(NewModal.Close, {})] }), body: _jsxs(NewModal.Body, { children: [_jsx(Box, { sx: { background: 'white', padding: '8px', borderRadius: '8px', marginBottom: 3 }, children: _jsx(Typography, { children: _jsx(Trans, { components: { b: _jsx("strong", { style: { fontWeight: 'bold' } }) }, i18nKey: "farmers.results.unpublish-recap", values: { farmName, farmerName, harvestYear } }) }) }), _jsx(Typography, { children: t('farmers.results.unpublish-warning') })] }), footer: _jsxs(NewModal.Footer, { children: [_jsx(NewModal.Cancel, { children: t('constants.cancel') }), _jsx(NewModal.Confirm, { disabled: isLoading, isLoading: isLoading, children: t('farmers.results.confirm-unpublish') })] }) }));
};

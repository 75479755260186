import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ALARMING_DATA_SCOPE, } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { AlarmingDataComments } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataComments';
import { useAlarmingDataGroupStyles } from '@features/alarmingData/components/AlarmingDataGroup/AlarmingDataGroup.style';
import { AlarmingDataValues } from '@features/alarmingData/components/AlarmingDataValues/AlarmingDataValues';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
export const AlarmingDataGroup = ({ className, title, showConfirm, onConfirm, isConfirmDisabled, isLoading, group, scope, }) => {
    const { t } = useTranslation();
    const { classes, cx } = useAlarmingDataGroupStyles();
    const alarmingDataIds = group.alarmingData.map((data) => data.id);
    const handleConfirm = () => onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(alarmingDataIds);
    return (_jsxs("div", { className: cx(classes.group, className), children: [title && showConfirm && (_jsxs("div", { className: classes.top, children: [_jsx(Typography, { fontWeight: 900, children: title }), showConfirm && (_jsx(Button, { disabled: isConfirmDisabled || isLoading, onClick: handleConfirm, className: classes.confirmButton, size: "small", children: isLoading ? (_jsx(CircularProgress, { size: 12, sx: { color: 'white' } })) : (t('buttons.confirm-selection')) }))] })), group.alarmingData.map((data) => {
                const item = scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD ? data.farmSeasonField : data.farmSeasonFieldCrop;
                return (_jsxs("div", { className: classes.infos, children: [_jsx(Typography, { className: classes.name, children: item === null || item === void 0 ? void 0 : item.name }), _jsx(AlarmingDataValues, { className: classes.values, values: data.values })] }, item === null || item === void 0 ? void 0 : item.id));
            }), _jsx(AlarmingDataComments, { alarmingDataIds: alarmingDataIds, comments: group.comments })] }));
};

import { useGetFarmersListQuery } from '@services';
import { useAppSelector } from '@hooks';
import { selectFarmersListCohorts, selectFarmersListContacts, selectFarmersListFarmSeasonStatuses, selectFarmersListPage, selectFarmersListPartners, selectFarmersListSearch, selectFarmersListSeasons, selectFarmersListSortingOptions, selectFarmersListUserStatuses, } from '@features/farmer/listFarmers/farmersList.slice';
import { PAGE_SIZE } from './Table/useFarmersTableData';
import { computeObjectAsQueryString } from '@utils/computeObjectAsQueryString';
export const useGetFarmersList = () => {
    var _a;
    const seasons = useAppSelector(selectFarmersListSeasons);
    const partners = useAppSelector(selectFarmersListPartners);
    const cohorts = useAppSelector(selectFarmersListCohorts);
    const contacts = useAppSelector(selectFarmersListContacts);
    const page = useAppSelector(selectFarmersListPage);
    const search = useAppSelector(selectFarmersListSearch);
    const userStatuses = useAppSelector(selectFarmersListUserStatuses);
    const farmSeasonStatuses = useAppSelector(selectFarmersListFarmSeasonStatuses);
    const sortingOptions = useAppSelector(selectFarmersListSortingOptions);
    const queryParams = {
        seasonId: (_a = seasons === null || seasons === void 0 ? void 0 : seasons.ids) !== null && _a !== void 0 ? _a : null,
        cohortId: cohorts === null || cohorts === void 0 ? void 0 : cohorts.map((cohort) => cohort.id),
        contactId: contacts === null || contacts === void 0 ? void 0 : contacts.map((contact) => contact.id),
        partnerId: partners === null || partners === void 0 ? void 0 : partners.map((partner) => partner.id),
        search: search,
        userStatus: userStatuses,
        farmSeasonStatus: farmSeasonStatuses,
        page: page,
        pageSize: PAGE_SIZE,
        sortField: sortingOptions.field,
        sortOrder: sortingOptions.order,
    };
    const queryString = computeObjectAsQueryString(queryParams);
    const { data, isLoading, isFetching, isError } = useGetFarmersListQuery({ queryString });
    return {
        data: (data === null || data === void 0 ? void 0 : data.farmers) || [],
        count: (data === null || data === void 0 ? void 0 : data.count) || 0,
        isLoading,
        isFetching,
        isError,
    };
};

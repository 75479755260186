import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from '@components/Avatar';
import { useGetFarmersList } from '@features/farmer/listFarmers/useGetFarmersList';
import { AvatarGroup, Tooltip } from '@mui/material';
import { getAvatarInitials } from '@utils/string';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_STATUS } from '@/types/user.types';
import { useAppSelector } from '@hooks';
import { selectFarmersListSortingOptions } from '@features/farmer/listFarmers/farmersList.slice';
import { useFarmersTableStyles } from '@features/farmer/listFarmers/Table/FarmersTable.style';
import { FARM_SEASON_STATUS } from '@/types/farmSeason.types';
import { IconHourglass } from '@components/Icons';
import { FARMER_LIST_COLUMNS } from '@/types/farmerListColumn.type';
import { usePermission } from '@hooks/usePermission';
export const PAGE_SIZE = 100;
export const useFarmersTableData = () => {
    const { t } = useTranslation();
    const sorting = useAppSelector(selectFarmersListSortingOptions);
    const { classes, cx } = useFarmersTableStyles({ sorting });
    const { canSeeAssociateMembers } = usePermission();
    const { data: rows, count, isLoading, isFetching } = useGetFarmersList();
    const getRowId = (row) => ((row === null || row === void 0 ? void 0 : row.user_id) ? `user-${row === null || row === void 0 ? void 0 : row.user_id}` : `invitation-${row === null || row === void 0 ? void 0 : row.invitation_id}`);
    const renderCellStylesByStatus = (params) => {
        const shouldApplyPendingStyles = params.row.user_status === USER_STATUS.INVITATION_PENDING;
        const shouldApplyRefusedStyles = params.row.user_status === USER_STATUS.INVITATION_REFUSED;
        return (_jsx("span", { className: cx(classes.row, shouldApplyPendingStyles && classes.pending, shouldApplyRefusedStyles && classes.refused), children: params.value }));
    };
    const columns = useMemo(() => {
        const displayedColumns = [
            {
                field: FARMER_LIST_COLUMNS.FULL_NAME,
                headerName: t('teams.grid.columns.full-name'),
                headerClassName: 'fullNameHeader',
                minWidth: 200,
                sortable: true,
                hideSortIcons: false,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` : '-',
                renderCell: renderCellStylesByStatus,
            },
            {
                field: FARMER_LIST_COLUMNS.EMAIL,
                headerName: t('teams.grid.columns.email'),
                width: 200,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.email,
                renderCell: (params) => {
                    return (_jsx(Tooltip, { title: params.value, placement: "right", arrow: true, children: renderCellStylesByStatus(params) }));
                },
            },
            {
                field: FARMER_LIST_COLUMNS.FARM_NAME,
                headerName: t('teams.grid.columns.farm-name'),
                minWidth: 150,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.farm_name,
                renderCell: (params) => {
                    return (_jsx(Tooltip, { title: params.value, placement: "right", arrow: true, children: renderCellStylesByStatus(params) }));
                },
            },
            {
                field: FARMER_LIST_COLUMNS.CONTACT,
                headerName: t('teams.grid.columns.contact'),
                minWidth: 130,
                headerAlign: 'center',
                align: 'center',
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.assigned_to,
                renderCell: (params) => {
                    var _a;
                    return (_jsx(AvatarGroup, { className: classes.avatarGroup, max: 3, children: (_a = params.value) === null || _a === void 0 ? void 0 : _a.map((contact) => (_jsx(Avatar, { className: classes.avatar, alt: `${contact.first_name} ${contact.last_name}`, children: _jsx(Tooltip, { title: contact.email, placement: "top", arrow: true, children: _jsx("div", { children: getAvatarInitials(contact.first_name, contact.last_name) }) }) }, Number(contact.id)))) }));
                },
            },
            {
                field: FARMER_LIST_COLUMNS.COHORT,
                headerName: t('teams.grid.columns.cohort'),
                headerClassName: 'cohortHeader',
                minWidth: 50,
                sortable: true,
                hideSortIcons: false,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.cohort && t(`farmers.cohort.${row.cohort}`),
                renderCell: (params) => _jsx("span", { className: cx(classes.row, classes.cohortRow), children: params.value }),
            },
            {
                field: FARMER_LIST_COLUMNS.USER_STATUS,
                headerName: t('teams.grid.columns.user-status'),
                minWidth: 150,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                valueGetter: ({ row }) => (row.user_status ? t(`statuses.user.${row.user_status}`) : '/'),
                renderCell: renderCellStylesByStatus,
            },
            {
                field: FARMER_LIST_COLUMNS.FARM_SEASON_STATUS,
                headerName: t('teams.grid.columns.farm-season-status'),
                headerClassName: 'farmSeasonStatusHeader',
                minWidth: 220,
                sortable: true,
                hideSortIcons: false,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.user_status === USER_STATUS.SUBSCRIBED && row.farm_season_status ? (_jsxs("div", { className: classes.farmSeasonStatusValueContainer, children: [t(`statuses.farm-season.${row.farm_season_status}`), ' ', row.farm_season_status === FARM_SEASON_STATUS.PENDING_RENEWAL && (_jsx(IconHourglass, { color: "warning", className: classes.iconHourglass }))] })) : ('/'),
                renderCell: renderCellStylesByStatus,
            },
        ];
        if (canSeeAssociateMembers) {
            const partnerColumn = {
                field: FARMER_LIST_COLUMNS.PARTNER,
                headerName: t('teams.grid.columns.partner'),
                minWidth: 150,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                valueGetter: ({ row }) => row.partner_name || '/',
                renderCell: (params) => {
                    return (_jsx(Tooltip, { title: params.value, placement: "right", arrow: true, children: renderCellStylesByStatus(params) }));
                },
            };
            displayedColumns.splice(3, 0, partnerColumn);
        }
        return displayedColumns;
    }, [t]);
    return { columns, rows, count, getRowId, isLoading, isFetching };
};

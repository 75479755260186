import { usePermission } from '@hooks/usePermission';
import { useGetPartnersQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetPartners = () => {
    const { canSeeAssociateMembers } = usePermission();
    const param = canSeeAssociateMembers ? undefined : skipToken;
    const { data, isLoading, isFetching, isError } = useGetPartnersQuery(param);
    return {
        data,
        isLoading,
        isFetching,
        isError,
    };
};

import { makeStyles } from 'tss-react/mui';
export const useFarmerDashboardCardStyles = makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(5),
        },
        bold: { fontWeight: theme.typography.fontWeightBold },
        modalContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(8),
        },
        modalFooter: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        warning: {
            marginTop: theme.spacing(2),
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

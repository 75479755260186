import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import useErrorCardStyle from './ErrorCard.style';
import { useNavigate } from 'react-router-dom';
import logoSoilcapitalSrc from '@images/logo-soilcapital.png';
import { ROUTES } from '@router/routes';
const ErrorCard = ({ className, buttonText, buttonUrl = ROUTES.HOME, errorTitle, errorDescription, }) => {
    const { t } = useTranslation();
    const { classes, cx } = useErrorCardStyle();
    const navigate = useNavigate();
    return (_jsxs(Paper, { className: cx(classes.container, className), children: [_jsx("img", { alt: "Logo", src: logoSoilcapitalSrc, style: { maxWidth: '140px', height: '38px' } }), _jsx(Box, { className: classes.iconContainer, children: _jsx(WarningIcon, { className: classes.icon }) }), _jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.contentGroup, children: [_jsx("div", { className: classes.titleContainer, children: _jsx(Typography, { align: "center", variant: "h4", children: errorTitle || t('errors.server-error') }) }), _jsx(Typography, { align: "center", variant: "body2", children: errorDescription || t('errors.error-occurred-while-fetching') })] }) }), _jsx(Box, { className: classes.buttonContainer, children: _jsx(Button, { color: "primary", onClick: () => navigate(buttonUrl), children: buttonText || t('common.home') }) })] }));
};
export default ErrorCard;

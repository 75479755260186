import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { transitions } from './transitions';
import MuiInputBase from './components/InputBase';
import MuiButton from './components/Button';
import MuiAppBar from './components/AppBar';
import MuiAutocomplete from './components/Autocomplete';
import MuiAvatar from './components/Avatar';
import MuiCheckbox from './components/Checkbox';
import MuiFormControl from './components/FormControl';
import MuiFormControlLabel from './components/FormControlLabel';
import MuiSelect from './components/Select';
import MuiSlider from './components/Slider';
import MuiTab from './components/Tab';
import MuiTabs from './components/Tabs';
import MuiTextField from './components/TextField';
import MuiTooltip from './components/Tooltip';
import MuiLinearProgress from './components/LinearProgress';
import MuiInputLabel from './components/InputLabel';
import MuiCard from './components/Card';
export const theme = createTheme({
    typography,
    palette,
    transitions,
    components: {
        MuiAppBar,
        MuiAutocomplete,
        MuiAvatar,
        MuiButton,
        MuiCheckbox,
        MuiFormControl,
        MuiFormControlLabel,
        MuiInputBase,
        MuiSelect,
        MuiSlider,
        MuiTab,
        MuiTabs,
        MuiTextField,
        MuiTooltip,
        MuiLinearProgress,
        MuiInputLabel,
        MuiCard,
    },
    shape: {
        borderRadius: 10,
    },
    spacing: 4,
});

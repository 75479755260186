var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTableStyles } from '@components/Table/Table.style';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
const Table = (_a) => {
    var { loading, components, rows, getRowId, columns, pagination = undefined, hideFooter = true } = _a, rest = __rest(_a, ["loading", "components", "rows", "getRowId", "columns", "pagination", "hideFooter"]);
    const { classes, cx } = useTableStyles();
    return (_jsx("div", { className: cx(classes.tableContainer, loading && classes.loadingTable), children: _jsx(DataGrid, Object.assign({ loading: loading, components: components, rows: rows, getRowId: getRowId, columns: columns, pagination: pagination, hideFooter: hideFooter }, rest)) }));
};
export const TableToolbar = () => {
    const { classes } = useTableStyles();
    return (_jsx(GridToolbarContainer, { className: classes.toolbarContainer, children: _jsx(GridToolbarColumnsButton, { variant: "outlined" }) }));
};
Table.Toolbar = TableToolbar;
export { Table };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useForgotPasswordMutation } from '@services';
import { LabelTextField } from '@components/LabelTextField';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useResetPasswordEmailFormStyle from './ResetPasswordEmailForm.style';
function ResetPasswordEmailForm() {
    var _a;
    const { classes } = useResetPasswordEmailFormStyle();
    const { t } = useTranslation();
    const [sendState, setSendState] = useState(false);
    const ResetPasswordEmailFormSchema = yup.object({
        email: yup.string().email(t('validations.email-invalid')).required(t('validations.email-invalid')),
    });
    const { register, handleSubmit, formState: { errors }, setError, } = useForm({
        reValidateMode: 'onChange',
        resolver: yupResolver(ResetPasswordEmailFormSchema),
    });
    const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        try {
            const res = yield forgotPassword(data).unwrap();
            if (res.result) {
                setSendState(true);
            }
        }
        catch (err) {
            if (err.data.error == 'validation.passwords.user') {
                setError('email', { type: 'NOT_FOUND', message: t('validations.email-doesnt-exist') });
            }
            else {
                // TODO: Implement toast for server error
                console.error(err);
            }
        }
    });
    return (_jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.titleContainer, children: _jsx(Typography, { align: "center", variant: "h2", children: t('titles.reset-your-password') }) }), _jsxs("form", { className: classes.form, id: "reset-password-form", noValidate: true, onSubmit: handleSubmit(onSubmit), children: [_jsx(LabelTextField, { InputProps: Object.assign({}, register('email', {
                            required: true,
                            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        })), autoComplete: "email", disabled: isLoading, error: errors.email !== undefined, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.email'), variant: "outlined", fullWidth: true, required: true }), _jsx(Box, { mt: "14px", mb: "30px", children: _jsx(Button, { type: "submit", variant: "contained", disableElevation: true, fullWidth: true, children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.send') }) }), sendState && (_jsx("div", { className: classes.sendMessage, children: _jsx(Typography, { align: "center", children: t('messages.reset-password-email-sent') }) }))] })] }));
}
export default ResetPasswordEmailForm;

import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme, { variant = 'neutral' }) => {
    const variantBackgroundMapping = {
        success: theme.palette.success.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        info: theme.palette.primary.main,
        neutral: theme.palette.grey[200],
    };
    return {
        container: {
            borderRadius: '8px',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: variantBackgroundMapping[variant],
        },
    };
});

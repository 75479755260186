import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Select } from '@components/Select';
import { useUserStatuses } from './useUserStatuses';
export const UserStatusFilter = ({ values, onChange }) => {
    const { t } = useTranslation();
    const userStatuses = useUserStatuses();
    const handleChange = (values) => onChange(values);
    const getOptionLabel = (userStatus) => userStatus;
    const getOptionValue = (userStatus) => userStatus;
    if (!userStatuses)
        return _jsx(_Fragment, {});
    return (_jsx(Select, { values: values !== null && values !== void 0 ? values : [], onChange: handleChange, options: userStatuses, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (userStatuses, allAreSelected) => (_jsx(Select.LabelWithPill, { label: t('teams.grid.columns.user-status'), selected: userStatuses.length, areAllSelected: allAreSelected })), renderOption: (option, props) => _jsx(Select.CheckboxOption, Object.assign({}, props, { label: t(`statuses.user.${option}`) })), renderOptionAll: (props) => _jsx(Select.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};

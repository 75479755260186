import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { NewModal } from '@components/NewModal/NewModal';
import { useResultPublication } from '@features/farmer/profile/resultsPublication/useResultPublication';
export const PublishResultsModal = ({ open, onClose, farmSeasonId, farmerName, farmName, harvestYear, }) => {
    const { t } = useTranslation();
    const { changeResultPublication, isLoading } = useResultPublication();
    const handlePublish = () => changeResultPublication({ farmSeasonId, published: true });
    return (_jsx(NewModal, { open: open, onClose: onClose, onConfirm: handlePublish, header: _jsxs(NewModal.Header, { children: [_jsx(NewModal.Title, { children: t('farmers.results.publish') }), _jsx(NewModal.Close, {})] }), body: _jsx(NewModal.Body, { style: { marginTop: 16, marginBottom: 16 }, children: _jsx(Typography, { children: _jsx(Trans, { components: { b: _jsx("strong", { style: { fontWeight: 'bold' } }) }, i18nKey: "farmers.results.publish-recap", values: { farmName, farmerName, harvestYear } }) }) }), footer: _jsxs(NewModal.Footer, { children: [_jsx(NewModal.Cancel, { children: t('constants.cancel') }), _jsx(NewModal.Confirm, { disabled: isLoading, isLoading: isLoading, children: t('farmers.results.confirm-publish') })] }) }));
};

import { useFarmersListStorageFilters } from '@features/farmer/listFarmers/useFarmersListStorageFilters';
import { useAppDispatch } from '@hooks';
import { changeCohorts, changeContacts, changeFarmSeasonStatuses, changeHiddenColumns, changePage, changePartners, changeSearch, changeSeasons, changeUserStatuses, } from '@features/farmer/listFarmers/farmersList.slice';
import { debounce } from '@mui/material';
import { useFarmersListFilterDefaultValues } from '@features/farmer/listFarmers/useFarmersListFilterDefaultValues';
export const useFarmersListFilters = () => {
    const dispatch = useAppDispatch();
    const { defaultSeason, defaultCohorts, defaultPartners, defaultContacts, defaultUserStatuses, defaultFarmSeasonStatuses, defaultHiddenColumns, } = useFarmersListFilterDefaultValues();
    const { storeSeasonFilterToStorage, deleteSeasonFilterFromStorage, storeCohortFilterToStorage, deleteCohortFilterFromStorage, storePartnerFilterToStorage, deletePartnerFilterFromStorage, storeContactFilterToStorage, deleteContactFilterFromStorage, storeUserStatusFilterToStorage, deleteUserStatusFilterFromStorage, storeFarmSeasonStatusFilterToStorage, deleteFarmSeasonStatusFilterFromStorage, storageHiddenColumnsFilterToStorage, } = useFarmersListStorageFilters();
    const handleSeasonChange = (season) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSeasons({ season }));
        storeSeasonFilterToStorage([season]);
    };
    const handleSearchChange = debounce((search) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSearch({ search }));
    }, 500);
    const handleSearchReset = () => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSearch({ search: '' }));
    };
    const handleCohortChange = (cohorts) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeCohorts({ cohorts }));
        storeCohortFilterToStorage(cohorts);
    };
    const handlePartnersChange = (partners) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changePartners({ partners }));
        storePartnerFilterToStorage(partners);
    };
    const handleContactsChange = (contacts) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeContacts({ contacts }));
        storeContactFilterToStorage(contacts);
    };
    const handleUserStatusChange = (userStatuses) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeUserStatuses({ userStatuses }));
        storeUserStatusFilterToStorage(userStatuses);
    };
    const handleFarmSeasonStatusChange = (farmSeasonStatuses) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeFarmSeasonStatuses({ farmSeasonStatuses }));
        storeFarmSeasonStatusFilterToStorage(farmSeasonStatuses);
    };
    const handleHiddenColumnsFilterChange = (hiddenColumns) => {
        dispatch(changeHiddenColumns({ hiddenColumns }));
        storageHiddenColumnsFilterToStorage(hiddenColumns);
    };
    const handleClearAll = () => {
        deleteSeasonFilterFromStorage();
        deleteCohortFilterFromStorage();
        dispatch(changeCohorts({ cohorts: [] }));
        deletePartnerFilterFromStorage();
        dispatch(changePartners({ partners: [] }));
        deleteContactFilterFromStorage();
        dispatch(changeContacts({ contacts: [] }));
        deleteUserStatusFilterFromStorage();
        dispatch(changeUserStatuses({ userStatuses: [] }));
        deleteFarmSeasonStatusFilterFromStorage();
        dispatch(changeFarmSeasonStatuses({ farmSeasonStatuses: [] }));
    };
    return {
        defaultSeason,
        defaultCohorts,
        defaultPartners,
        defaultContacts,
        defaultUserStatuses,
        defaultFarmSeasonStatuses,
        defaultHiddenColumns,
        handleSeasonChange,
        handleSearchChange,
        handleSearchReset,
        handleCohortChange,
        handlePartnersChange,
        handleContactsChange,
        handleUserStatusChange,
        handleFarmSeasonStatusChange,
        handleHiddenColumnsFilterChange,
        handleClearAll,
    };
};

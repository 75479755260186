import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from '@components/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
import { useFarmerProfileHeaderCard } from '@features/farmer/profile/components/FarmerProfileHeaderCard/FarmerProfileHeaderCard.style';
import { VisitFarmButton } from '@features/farmer/profile/visitFarm/VisitFarmButton';
import { DeleteFarmerButton } from '@features/farmer/delete/DeleteFarmerButton';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { CARBON_RESULT_MODEL } from '@utils/constants';
export const FarmerProfileHeaderCard = () => {
    var _a, _b, _c, _d, _e;
    const { classes } = useFarmerProfileHeaderCard();
    const { t } = useTranslation();
    const { invitationId } = useGetFarmerProfileParamsFromUrl();
    const { profile, isLoading } = useGetFarmerProfileFromUrlId();
    if (isLoading)
        return _jsx(Skeleton, { height: 160, variant: "rounded", animation: "wave" });
    if (!profile)
        return _jsx(_Fragment, {});
    const { first_name, last_name, email, phone_number, is_eligible, initials } = profile || {};
    return (_jsx(_Fragment, { children: _jsxs(Card, { className: classes.farmerCard, children: [_jsxs(Box, { className: classes.infoContainer, children: [_jsx(Avatar, { className: classes.avatar, children: initials }), _jsxs(Box, { children: [_jsxs(Typography, { variant: 'h3', children: [first_name, " ", last_name] }), !is_eligible && (_jsx(Box, { display: "flex", children: _jsxs(Typography, { variant: "body2", color: "error", children: [_jsx(CloseIcon, { fontSize: "small", style: {
                                                    verticalAlign: 'middle',
                                                    fontSize: '12px',
                                                    marginRight: '4px',
                                                } }), t('labels.user-not-eligible')] }) })), _jsxs(Typography, { mt: "10px", variant: "subtitle1", className: classes.wrapIcon, children: [_jsx(Box, { mr: "5px", mt: "1px", children: _jsx(EmailIcon, { style: { fontSize: '16px', marginTop: '2px' } }) }), _jsx("span", { children: email }), _jsx(Box, { ml: "30px", mr: "5px", mt: "1px", children: _jsx(PhoneIcon, { style: { fontSize: '16px', marginTop: '2px' } }) }), phone_number] })] })] }), _jsx(Box, { className: classes.buttonContainer, children: _jsxs(_Fragment, { children: [(profile === null || profile === void 0 ? void 0 : profile.userId) && (_jsx(VisitFarmButton, { userId: profile === null || profile === void 0 ? void 0 : profile.userId, farmSeason: (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.farm_seasons) === null || _c === void 0 ? void 0 : _c[0], isDndcFarm: ((_e = (_d = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _d === void 0 ? void 0 : _d.farm) === null || _e === void 0 ? void 0 : _e.carbon_result_model) === CARBON_RESULT_MODEL.DNDC })), _jsx(DeleteFarmerButton, { farmerId: (profile === null || profile === void 0 ? void 0 : profile.userId) ? Number(profile === null || profile === void 0 ? void 0 : profile.userId) : undefined, invitationId: invitationId })] }) })] }) }));
};

import { usePermission } from './usePermission';
export const ROLE_GROUP = {
    PARTNER: 'partner',
    ASSOCIATE: 'associate',
};
export const useUserRoleGroup = () => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers } = usePermission();
    const isPartner = canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers;
    // const isAssociate = canSeeAssociateFarmers || canSeeAssociateAssignedFarmers;
    if (isPartner)
        return { roleGroup: ROLE_GROUP.PARTNER };
    return { roleGroup: ROLE_GROUP.ASSOCIATE };
};

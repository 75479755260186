import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { usePermission } from '@hooks/usePermission';
import { ApiToggle, useGetFarmerProfileQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetFarmerProfileInfos = () => {
    const { userId, invitationId } = useGetFarmerProfileParamsFromUrl();
    const id = userId || invitationId;
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const partnerCanFetchProfile = (canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers) && !!id;
    const associateCanFetchProfile = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && !!id;
    const fetchParam = partnerCanFetchProfile && id
        ? { toggle: ApiToggle.PARTNER, invitationId: id }
        : associateCanFetchProfile && id
            ? { toggle: ApiToggle.ASSOCIATE, userId: id }
            : skipToken;
    return useGetFarmerProfileQuery(fetchParam);
};

import { makeStyles } from 'tss-react/mui';
export const useTimelineStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    step: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    trigger: {
        padding: 0,
        minWidth: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        // Make the clickable area larger
        '&:after': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            content: '""',
            width: 100,
            height: 100,
            backgroundColor: 'transparent',
        },
    },
    node: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center',
        position: 'relative',
    },
    label: {
        position: 'absolute',
        left: 16,
        transform: 'translate(-50%, -50%)',
        top: '150%',
        marginTop: theme.spacing(2),
        whiteSpace: 'pre',
        textAlign: 'center',
    },
    selectedLabel: {
        fontWeight: 'bold',
    },
    segment: {
        flexGrow: 1,
        height: '1px',
        borderTopStyle: 'dotted',
        borderTopWidth: '3px',
        borderTopColor: theme.palette.grey[400],
    },
    segmentDone: {
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.success.dark,
    },
    outerCircle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 32,
        height: 32,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'inherit',
    },
    innerCircle: {
        width: 16,
        height: 16,
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'inherit',
    },
    outerCircleNotStarted: {
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[400],
    },
    outerCircleInProgress: {
        backgroundColor: 'white',
        borderColor: theme.palette.success.dark,
    },
    outerCircleDone: {
        backgroundColor: theme.palette.success.dark,
        borderColor: theme.palette.success.dark,
    },
    innerCircleNotStarted: {
        borderColor: 'white',
    },
    innerCircleInProgress: {
        backgroundColor: theme.palette.success.dark,
    },
}));

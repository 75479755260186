import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@router/routes';
function Dashboard() {
    // const { classes } = useDashboardStyle();
    return _jsx(Navigate, { to: ROUTES.FARMERS });
    // return (
    //     <Box className={classes.container}>
    //         <DashboardLayout />
    //     </Box>
    // );
}
export default Dashboard;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconDownload = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", color: color }, rest, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.3559 10.7084H9.46156C8.96094 10.7084 8.71343 11.3159 9.06781 11.6702L11.6497 14.2521C11.8691 14.4715 12.2235 14.4715 12.4428 14.2521L15.0247 11.6702C15.3791 11.3159 15.126 10.7084 14.6253 10.7084H13.731V7.89585C13.731 7.58647 13.4778 7.33334 13.1685 7.33334H10.9184C10.6091 7.33334 10.3559 7.58647 10.3559 7.89585V10.7084ZM8.67383 16.6667C8.36445 16.6667 8.11133 16.4135 8.11133 16.1042C8.11133 15.7948 8.36445 15.5417 8.67383 15.5417H15.4239C15.7332 15.5417 15.9864 15.7948 15.9864 16.1042C15.9864 16.4135 15.7332 16.6667 15.4239 16.6667H8.67383Z", fill: "currentColor" }) })));
};

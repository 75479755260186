import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoLabelValueBox } from '../InfoLabelValueBox/InfoLabelValueBox';
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
export const FarmerProfileCompanyCard = () => {
    var _a;
    const { t } = useTranslation();
    const { profile, isLoading } = useGetFarmerProfileFromUrlId();
    const company = profile === null || profile === void 0 ? void 0 : profile.company;
    if (isLoading)
        return _jsx(Skeleton, { height: 80, variant: "rounded", animation: "wave" });
    if (!company)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: _jsxs(Card, { children: [_jsx(InfoLabelValueBox, { label: t('company.name'), value: company.name, placeholder: t('placeholders.company-name') }), _jsx(InfoLabelValueBox, { label: t('company.vat'), value: (_a = company === null || company === void 0 ? void 0 : company.tax_id) !== null && _a !== void 0 ? _a : undefined, placeholder: t('placeholders.company-vat') })] }) }));
};

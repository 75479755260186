import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileHeaderCard = makeStyles()(() => {
    return {
        farmerCard: {
            position: 'relative',
        },
        infoContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        avatar: {
            height: '120px',
            width: '120px',
            border: 0,
            fontSize: '40px',
            marginRight: '16px',
            boxShadow: 'none',
        },
        buttonContainer: {
            top: 15,
            right: 20,
            position: 'absolute',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex',
        },
    };
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTableStyles } from '@components/Table/Table.style';
import { useRef } from 'react';
import { Box, LinearProgress as MUILinearProgress, Skeleton } from '@mui/material';
export const TableLoader = () => {
    var _a;
    const { classes } = useTableStyles();
    const tableRef = useRef(null);
    const numberOfRows = Math.round((((_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0) / 45);
    return (_jsxs(Box, { className: classes.loaderContainer, ref: tableRef, children: [Array.from({ length: numberOfRows }).map((row, i) => (_jsxs("div", { children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { sx: { ml: 2 }, variant: "text", width: 235, height: 40 }), _jsx(Skeleton, { sx: { ml: 2 }, variant: "text", width: 235, height: 40 }), _jsx(Skeleton, { sx: { ml: 2 }, variant: "text", width: 90, height: 40 }), _jsx(Box, { sx: { ml: 2, width: 90, display: 'flex', justifyContent: 'center' }, children: _jsx(Skeleton, { variant: "circular", width: 25, height: 25 }) }), _jsx(Skeleton, { sx: { ml: 2 }, variant: "text", width: 165, height: 40 })] }), _jsx(Box, { height: 5, width: "100%", bgcolor: "#fff" })] }, i))), _jsx(MUILinearProgress, { className: classes.loader })] }));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePermission } from '@hooks/usePermission';
import { ConfirmFarmerRemovalModal } from '@features/farmer/delete/ConfirmFarmerRemovalModal';
import { useToggle } from '@hooks/useToggle';
import { useTranslation } from 'react-i18next';
export const DeleteFarmerButton = ({ farmerId, invitationId }) => {
    const { canDeleteAllFarmers } = usePermission();
    const { t } = useTranslation();
    const { open: confirmModalOpen, handleOpen: handleConfirmModalOpen, handleClose: handleConfirmModalClose, } = useToggle();
    return (_jsxs(_Fragment, { children: [canDeleteAllFarmers && (_jsx(Button, { onClick: handleConfirmModalOpen, startIcon: _jsx(DeleteIcon, { style: { fontSize: '16px' } }), color: "error", variant: 'text', children: t('farmers.remove-farmer') })), _jsx(ConfirmFarmerRemovalModal, { open: confirmModalOpen, farmerId: farmerId, invitationId: invitationId, onClose: handleConfirmModalClose })] }));
};

import { makeStyles } from 'tss-react/mui';
export const useSeasonFilterStyles = makeStyles()((theme) => ({
    select: {},
    container: {
        '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: 308,
            gap: theme.spacing(1),
            padding: theme.spacing(2),
            flexWrap: 'wrap',
        },
    },
    seasonBox: {
        padding: theme.spacing(3),
        border: `solid 1px ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.primary.main,
        '&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
            color: 'white',
            backgroundColor: theme.palette.success.dark,
            borderColor: theme.palette.success.dark,
        },
    },
    season: {
        color: 'inherit',
    },
}));

import { makeStyles } from 'tss-react/mui';
export const useSearchInputStyle = makeStyles()((theme) => ({
    textField: {
        width: '100%',
        '& .MuiInputBase-root': {
            padding: 0,
            '& input': {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                paddingLeft: 0,
                paddingRight: 0,
                height: theme.spacing(6),
            },
        },
    },
    searchIcon: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    resetIcon: {
        '&:hover': { cursor: 'pointer' },
        width: 16,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

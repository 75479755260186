import { makeStyles } from 'tss-react/mui';
import { FARMERS_SORTABLE_FIELDS } from '@features/farmer/listFarmers/farmersList.slice';
export const useFarmersTableStyles = makeStyles()((theme, { sorting }) => ({
    avatarGroup: {
        '& .MuiAvatar-root': {
            width: 20,
            height: 20,
            fontSize: theme.typography.body2.fontSize,
            padding: theme.spacing(1),
            backgroundColor: '#f6f3E5',
            color: theme.palette.secondary.dark,
            boxShadow: 'none',
        },
    },
    avatar: {
        height: 20,
        width: 20,
        padding: theme.spacing(1),
    },
    email: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    row: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    cohortRow: {
        paddingLeft: theme.spacing(6),
    },
    pending: {
        color: theme.palette.grey['500'],
        '&:after': {
            color: theme.palette.grey['500'],
        },
    },
    farmersTable: {
        '& .row-INVITATION_REFUSED': {
            background: `${theme.palette.error.light} !important`,
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
        },
        '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
        },
        '.fullNameHeader': {
            '.MuiDataGrid-sortIcon': {
                color: sorting.field === FARMERS_SORTABLE_FIELDS.FULL_NAME
                    ? theme.palette.primary.main
                    : theme.palette.grey['300'],
            },
        },
        '.cohortHeader': {
            '.MuiDataGrid-sortIcon': {
                color: sorting.field === FARMERS_SORTABLE_FIELDS.COHORT
                    ? theme.palette.primary.main
                    : theme.palette.grey['300'],
            },
        },
        '.farmSeasonStatusHeader': {
            '.MuiDataGrid-sortIcon': {
                color: sorting.field === FARMERS_SORTABLE_FIELDS.FARM_SEASON_STATUS
                    ? theme.palette.primary.main
                    : theme.palette.grey['300'],
            },
        },
    },
    refused: {
        color: theme.palette.error.main,
        '&:after': {
            color: theme.palette.error.main,
        },
    },
    farmSeasonStatusValueContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    iconHourglass: {
        width: 12,
    },
}));

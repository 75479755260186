import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { BoxedIcon } from '@components/BoxedIcon';
import { Box, Button, Typography } from '@mui/material';
import { IconCross } from '@components/Icons';
import { useBannerStyles } from '@components/Banner/Banner.style';
const Banner = ({ variant = 'neutral', Icon, Action, Close, children, }) => {
    const variantColor = {
        success: 'success.main',
        warning: 'warning.main',
        error: 'error.main',
        info: 'primary.main',
        neutral: 'grey.400',
    };
    return (_jsxs(Box, { sx: {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 4,
            paddingRight: 4,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: 1,
            borderColor: variantColor[variant],
        }, children: [Icon, " ", children, _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 2, marginLeft: 'auto' }, children: [Action, " ", Close] })] }));
};
export const BannerIcon = ({ variant, icon }) => {
    const { classes } = useBannerStyles();
    return _jsx(BoxedIcon, { className: classes.bannerIcon, variant: variant, icon: icon });
};
export const BannerBody = ({ children }) => {
    return _jsx(Typography, { variant: "subtitle1", children: children });
};
export const BannerAction = ({ children, onClick, }) => {
    return (_jsx(Button, { variant: "text", sx: {
            marginLeft: 'auto',
            textDecoration: 'underline',
            fontSize: '14px',
        }, onClick: onClick, children: children }));
};
export const BannerClose = ({ onClick }) => {
    return (_jsx(Button, { variant: "text", sx: {
            marginLeft: 'auto',
            padding: 2,
            minWidth: 0,
            minHeight: 0,
            width: 32,
            height: 32,
            borderRadius: '50%',
        }, onClick: onClick, children: _jsx(IconCross, { sx: { width: 14 } }) }));
};
Banner.Icon = BannerIcon;
Banner.Body = BannerBody;
Banner.Action = BannerAction;
Banner.Close = BannerClose;
export { Banner };

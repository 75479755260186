var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ALARMING_DATA_SCOPE, } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { AlarmingDataCard } from '@features/alarmingData/AlarmingDataCard/AlarmingDataCard';
import { AlarmingFarmSeasonData } from '@features/alarmingData/AlarmingFarmSeasonData/AlarmingFarmSeasonData';
import { useConfirmAlarmingDataMutation } from '@services';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
const AlarmingDataCheck = ({ check }) => {
    const { t } = useTranslation();
    const [confirmAlarmingData, { isLoading }] = useConfirmAlarmingDataMutation();
    const { enqueueSnackbar } = useSnackbar();
    const handleConfirmAlarmingData = (alarmingDataIds) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield confirmAlarmingData({
                alarmingDataIds,
            }).unwrap();
            enqueueSnackbar(t('snackbar.alarming-data-confirmed'), { variant: 'success' });
        }
        catch (_e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    if (check.scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD)
        return (_jsx(AlarmingDataCard, { scope: ALARMING_DATA_SCOPE.FARM_SEASON_FIELD, check: check, onConfirm: handleConfirmAlarmingData, isLoading: isLoading }, check.id));
    if (check.scope === ALARMING_DATA_SCOPE.FARM_SEASON_FIELD_CROP)
        return (_jsx(AlarmingDataCard, { scope: ALARMING_DATA_SCOPE.FARM_SEASON_FIELD_CROP, check: check, onConfirm: handleConfirmAlarmingData, isLoading: isLoading }, check.id));
    if (check.scope === ALARMING_DATA_SCOPE.FARM_SEASON)
        return (_jsx(AlarmingFarmSeasonData, { check: check, onConfirm: handleConfirmAlarmingData, isLoading: isLoading }, check.id));
    return _jsx(_Fragment, {});
};
export const AlarmingDataChecksList = ({ data }) => {
    return (_jsx(_Fragment, { children: data.map((check) => (_jsx(AlarmingDataCheck, { check: check }, check.id))) }));
};

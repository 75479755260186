import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePermission } from '@hooks/usePermission';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import useSideBarStyle from './SideBar.style';
function SideBar() {
    const { classes } = useSideBarStyle();
    const { t } = useTranslation();
    // const { canSeePartnerMembers, canSeeDashboard } = usePermission();
    const { canSeePartnerMembers } = usePermission();
    // const canSeeMembers = canSeePartnerMembers || canSeeAssociateMembers;
    const canSeeMembers = canSeePartnerMembers;
    const navigate = useNavigate();
    // const matchRoot = useMatch('/');
    const matchMembers = useMatch('/members/*');
    const matchFarmers = useMatch('/farmers/*');
    return (_jsxs(List, { "aria-label": "main folders", className: classes.list, component: "nav", children: [canSeeMembers && (_jsxs(ListItem, { className: `${classes.navLink} ${matchMembers && classes.navLinkActive}`, component: "span", button: true, onClick: () => navigate('/members'), children: [_jsx(ListItemIcon, { children: _jsx(SvgIcon, { className: classes.listItemIcon, component: GroupsOutlinedIcon }) }), _jsx("div", { className: classes.nameContainer, children: _jsx(ListItemText, { primary: t('titles.my-members'), primaryTypographyProps: { className: classes.listItemText } }) })] })), _jsxs(ListItem, { className: `${classes.navLink} ${matchFarmers && classes.navLinkActive}`, component: "span", button: true, onClick: () => navigate('/farmers'), children: [_jsx(ListItemIcon, { children: _jsx(SvgIcon, { className: classes.listItemIcon, component: GroupsOutlinedIcon }) }), _jsx("div", { className: classes.nameContainer, children: _jsx(ListItemText, { primary: t('titles.farmers'), primaryTypographyProps: { className: classes.listItemText } }) })] })] }));
}
export default SideBar;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid } from '@mui/material';
import { useFarmerProfilePageStyles } from '@/pages/FarmerProfilePage/FarmerProfilePage.style';
import { FarmerProfileAboutCard } from '@features/farmer/profile/components/FarmerProfileAboutCard/FarmerProfileAboutCard';
import { DeferredPaymentBanner } from '@features/farmer/profile/deferredPayment/DeferredPaymentBanner';
import { FarmerProfileHeaderCard } from '@features/farmer/profile/components/FarmerProfileHeaderCard/FarmerProfileHeaderCard';
import { FarmerProfileFarmCard } from '@features/farmer/profile/components/FarmerProfileFarmCard/FarmerProfileFarmCard';
import { FarmerProfileCompanyCard } from '@features/farmer/profile/components/FarmerProfileCompanyCard/FarmerProfileCompanyCard';
import { FarmerDashboardCard } from '@features/farmer/profile/components/FarmerDashboard/FarmerDashboardCard';
import { FarmerProfileTopNav } from '@features/farmer/profile/components/FarmerProfileTopNav/FarmerProfileTopNav';
import { FarmerProfileInfos } from '@features/farmer/profile/components/FarmerProfileInfos/FarmerProfileInfos';
import { FarmerProfileTimeline } from '@features/farmer/profile/components/FarmerProfileTimeline/FarmerProfileTimeline';
// TODO - Rename.
const NewFarmerProfilePage = () => {
    const { classes } = useFarmerProfilePageStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsx(FarmerProfileTopNav, {}), _jsxs("div", { className: classes.farmerProfileSection, children: [_jsx(FarmerProfileInfos, {}), _jsx("div", { className: classes.farmerProfileSeasonsContainer, children: _jsx(FarmerProfileTimeline, {}) })] })] }));
};
// TODO - Remove.
const OldFarmerProfilePage = () => {
    const { classes } = useFarmerProfilePageStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsx(FarmerProfileTopNav, {}), _jsxs("div", { className: classes.farmerProfileSection, children: [_jsx(DeferredPaymentBanner, {}), _jsx(FarmerProfileHeaderCard, {}), _jsxs(Grid, { mt: "0px", container: true, spacing: 4, children: [_jsxs(Grid, { item: true, xs: 6, children: [_jsx(FarmerProfileAboutCard, {}), _jsx(Box, { mt: 4, children: _jsx(FarmerProfileFarmCard, {}) }), _jsx(Box, { mt: 4, children: _jsx(FarmerProfileCompanyCard, {}) })] }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FarmerDashboardCard, {}) })] })] })] }));
};
// TODO - Remove.
export const FarmerProfilePage = () => {
    return process.env.NEW_FARMER_PROFILE_PAGE === 'true' ? _jsx(NewFarmerProfilePage, {}) : _jsx(OldFarmerProfilePage, {});
};

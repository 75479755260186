var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, ApiToggle } from '@services';
export const PRODUCT_PLAN = {
    BASIC: 'BASIC',
    STANDARD: 'STANDARD',
};
export const PRODUCT_PLAN_STATUSES = {
    PENDING: 'pending',
    ACTIVE: 'active',
};
export var PROGRESS_STATUS;
(function (PROGRESS_STATUS) {
    PROGRESS_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
    PROGRESS_STATUS["COMPLETED"] = "COMPLETED";
    PROGRESS_STATUS["NOT_STARTED"] = "NOT_STARTED";
})(PROGRESS_STATUS || (PROGRESS_STATUS = {}));
export var PROGRESS_CODES;
(function (PROGRESS_CODES) {
    PROGRESS_CODES["PREPARATION"] = "preparation";
    PROGRESS_CODES["FARM_PROFILE"] = "farm-profile";
    PROGRESS_CODES["DRAINAGE"] = "drainage";
    PROGRESS_CODES["ROTATION"] = "rotation";
    PROGRESS_CODES["IRRIGATION"] = "irrigation";
    PROGRESS_CODES["AGROFORESTRY"] = "agroforestry";
    PROGRESS_CODES["LIVESTOCK"] = "livestock";
    PROGRESS_CODES["SOIL_ANALYSIS"] = "soil-analysis";
    PROGRESS_CODES["TECHNICAL_ITINERARY"] = "technical-itinerary";
    PROGRESS_CODES["HISTORY"] = "history";
})(PROGRESS_CODES || (PROGRESS_CODES = {}));
export const farmerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFarmersList: builder.query({
            query: ({ queryString }) => ({
                url: `/private/farmers/list${queryString}`,
            }),
            providesTags: ['Farmers'],
        }),
        getFarmerProfile: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                return __awaiter(this, void 0, void 0, function* () {
                    var _a, _b, _c, _d;
                    try {
                        if (arg.toggle === ApiToggle.PARTNER && (arg === null || arg === void 0 ? void 0 : arg.invitationId)) {
                            const res = yield fetchWithBQ(`/private/farmers/invitation/${arg.invitationId}/profile`);
                            return { data: res.data };
                        }
                        if (arg.toggle === ApiToggle.ASSOCIATE && (arg === null || arg === void 0 ? void 0 : arg.userId)) {
                            const res = yield fetchWithBQ(`/private/farmers/user/${arg.userId}/profile`);
                            return { data: res.data };
                        }
                        return { data: null };
                    }
                    catch (error) {
                        return {
                            error: {
                                status: (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not fetch farmers profiles',
                            },
                        };
                    }
                });
            },
            providesTags: ['Farmer'],
        }),
        getFarmerProfileFarmSeasons: builder.query({
            query: ({ userId }) => ({
                url: `/private/farmers/${userId}/profile/farm-seasons`,
            }),
        }),
        // TODO remove
        getFarmerFullProfile: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                return __awaiter(this, void 0, void 0, function* () {
                    var _a, _b, _c, _d;
                    try {
                        if (arg.toggle === ApiToggle.PARTNER && (arg === null || arg === void 0 ? void 0 : arg.invitationId)) {
                            const res = yield fetchWithBQ(`/private/farmers/profile/partner/${arg.invitationId}`);
                            return { data: res.data };
                        }
                        if (arg.toggle === ApiToggle.ASSOCIATE && (arg === null || arg === void 0 ? void 0 : arg.userId)) {
                            const res = yield fetchWithBQ(`/private/farmers/profile/associate/${arg.userId}`);
                            return { data: res.data };
                        }
                        return { data: null };
                    }
                    catch (error) {
                        return {
                            error: {
                                status: (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not fetch farmers profiles',
                            },
                        };
                    }
                });
            },
            providesTags: ['Farmer'],
        }),
        postDeferredPayment: builder.mutation({
            query: ({ toggle, farm_season_product_plan_id, farm_season_id }) => ({
                url: `/private/farmers/deferred-payment/${toggle}`,
                method: 'POST',
                body: { farm_season_product_plan_id, farm_season_id },
            }),
        }),
    }),
});
export const { useGetFarmerFullProfileQuery, useGetFarmerProfileQuery, useGetFarmerProfileFarmSeasonsQuery, useGetFarmersListQuery, usePostDeferredPaymentMutation, } = farmerApi;
